import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProposal } from "../features/counter/proposalSlice";
import { SignMessage, Submit } from "./DelegateSignedFunction";
import { apiBaseUrl } from "../Common/config";
import axios from "axios";
import { AuthContext } from "../App";
import { useWeb3React } from "@web3-react/core";

const DelegateSignature = ({ address, setProgress }) => {
  const [selected, setSelected] = useState(false);
  const [counter, setCounter] = useState(0);
  const { delegateBySign, networkId } = useSelector(selectProposal);
  const { web3Instance } = useContext(AuthContext)
  const web3reactContext = useWeb3React();


  useEffect(() => {
    let proceed = true;
    const getCounter = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}delegate/counter`);
        const apiResponse = response ? response.data : {};
        setCounter(apiResponse.data);
      } catch (e) { }
    };
    if (proceed) {
      getCounter();

    }
    return () => {
      proceed = false;
    }

  }, []);

  return (
    <div className="flex flex-col justify-center items-center mt-4">
      {/* <div className="text-sm text-justify">
        If you would like to vote gaslessly by signing a transaction, use the
        below voting buttons. The votes must then be submitted ahead of the end
        timer for the votes to count. You must delegate to the gasless contract
        once before you can use this.
      </div> */}

      <div className="flex md:flex-row sm:flex-col 2xs:flex-col flex-row justify-center gap-4 items-center mt-4 mb-4">
        <button
          type="submit"
          className={`delegate-button ${selected ? "cursor-default" : "cursor-pointer"
            }`}
          disabled={selected}
          onClick={() => {
            SignMessage(
              address,
              { setSelected },
              setProgress,
              web3Instance,
              networkId,
              web3reactContext
            );
          }}
        >
          Delegate By Sign
        </button>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className={`delegate-button ${selected ? "cursor-default" : "cursor-pointer"
              }`}
            onClick={() => {
              Submit(web3reactContext, networkId);
            }}
          >
            Submit {counter} Delegates
          </button>
        </div>
      </div>
      {/* <div className="text-sm text-justify">
        Note: the gasless voting will apply only 400 unique votes per
        submission. If the counter is at 400, submit the transaction to be able
        to continue voting.
      </div> */}
    </div>
  );
};
export default DelegateSignature;
