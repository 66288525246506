import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { network } from "../../Common/config";

// const RPC_URLS = {
//     1: 'https://mainnet.infura.io/v3/b8091fc239d74e67b92711d292ae152a',
// };

export const injected = new InjectedConnector({
  supportedChainIds: [network],
});

export const walletconnect = new WalletConnectConnector({
	rpc:{1:"https://mainnet.infura.io/v3/b8091fc239d74e67b92711d292ae152a"},
	qrcode: true,
	pollingInterval: 15000
});

export function resetWalletConnector(connector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}

export const walletlink = new WalletLinkConnector({
  url: "https://mainnet.infura.io/v3/b8091fc239d74e67b92711d292ae152a",
  appName: "cultDao",
  supportedChainIds: [network],
});
