import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  proposalLoading,
  selectProposal,
  setCustomRoute,
} from "../../features/counter/proposalSlice";
import BigPaperCover from "../BigPaperCover";
import ClosedProposalItems from "./ClosedProposalItems";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { apiBaseUrl, network, baseUrl } from "../../Common/config";
import axios from "axios";
function ClosedProposal() {
  const { proposals, loading, networkId } =
    useSelector(selectProposal);
  const [pendingData, setPendingData] = useState([]);
  const dispatch = useDispatch();

  const fetchCloseProposal = () => {
    axios.get(`${apiBaseUrl}proposals/closed`).then((res) => {
      dispatch(proposalLoading(false));
      if (res?.data?.data?.length) {
        setPendingData(res?.data?.data);
      } else {
        setPendingData([]);
      }
    });
  }


  useEffect(() => {
    dispatch(proposalLoading(true));
    dispatch(setCustomRoute("closedProposal"));
    fetchCloseProposal()

    return () => {
      setPendingData([]);
    }
  }, [proposals]);


  return (
    <BigPaperCover to="proposalOptions">
      <p className="4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-8 2xl:text-4xl xl:text-3xl 2xl:mb-8 2xl:mt-6 text-2xl font-semibold font-Nixie mb-4">
        Closed Proposals
      </p>
      <div className="overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm min-w-full 2xl:max-h-55 max-h-50">
        {!loading && pendingData ? (
          pendingData?.length ? (
            pendingData?.map((details, i) => (
              <ClosedProposalItems details={details} key={i} />
            ))
          ) : (
            <div className="flex sm:w-full justify-evenly items-center">
              {networkId == network ? (
                <p className="text-xl sm:text-2xl m-auto font-semibold 3xl:text-4xl ">
                  No completed acts found
                </p>
              ) : (
                <p className="text-2xl m-auto font-semibold 3xl:text-4xl">
                  Wrong Network
                </p>
              )}
            </div>
          )
        ) : (
          <div className="flex sm:w-5/12 m-auto w-full justify-evenly items-center">
            <p className="3xl:text-6xl  3xl:mt-10 sm:text-2xl text-xl">
              Loading ...
            </p>
            <AiOutlineLoading3Quarters className="3xl:mt-10 3xl:w-16 3xl:h-16 animate-spin sm:w-8 sm:h-8 h-6 w-6" />
          </div>
        )}
      </div>
      <Link
        to={`${baseUrl}proposalOptions`}
        className="text-lg underline font-Nixie mt-3 absolute -bottom-3 sm:invisible visible"
      >
        Back
      </Link>
    </BigPaperCover>
  );
}

export default ClosedProposal;
