import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BigPaperCover from "../BigPaperCover";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProposal,
  allProposal,
  proposalLoading,
} from "../../features/counter/proposalSlice";
import {
  approveOrRejectProposal,
  cancelProposal,
  updateProposal,
} from "../../Common/governanceFunctions";
import { toast, ToastContainer } from "react-toastify";
import {
  getAccount,
  highestStaker,
  checkVotingPowerForProposal,
} from "../../Common/cultDaoFunctions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Signature from "./CastVoteSignatre";
import { network, baseUrl } from "../../Common/config";
import { AuthContext } from "../../App";
import { useWeb3React } from "@web3-react/core";

function ProposalDetail() {
  const location = useLocation();
  const [proposalData, setProposalData] = useState(location?.state);
  const { networkId, customRoute } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const web3reactContext = useWeb3React();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const d = async () => {
      setCurrentAccount(web3reactContext.account);
    };
    d();
    return () => {
      setCurrentAccount("");
    };
  }, []);

  const cancelP = async (id) => {
    setProgressMessage("Canceling Proposal");
    setProgress(true);
    const res = await cancelProposal(id, web3reactContext);
    if (res.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
    }
    setProgress(false);
    dispatch(proposalLoading(true));
    updateProposal(dispatch, allProposal, web3reactContext);
    dispatch(proposalLoading(false));
  };

  const approveProposalOrReject = async (id, value, startBlock) => {
    setProgressMessage("Submitting Vote");
    if (!web3reactContext.account) {
      notify("Please connect with metamask");
      return 0;
    }

    if (startBlock > 0) {
      const votingPower = await checkVotingPowerForProposal(
        startBlock,
        web3reactContext
      );
      if (votingPower === "0") {
        notify("You need to delegate first to vote on proposal.");
        return 0;
      }
    }

    if (value) {
      const checkHighestStaker = await highestStaker(web3reactContext);
      if (!checkHighestStaker) {
        setProgress(true);
        const res = await approveOrRejectProposal(id, value, web3reactContext);
        if (res.code === 4001) {
          notify("Transaction Rejected");
        } else {
          notify("Transaction Confirmed");
          setProgress(false);
          navigate("/proposalOptions");
        }
        setProgress(false);
        return "";
      } else {
        notify("Guardians cannot vote");
      }
    } else {
      setProgress(true);
      const res = await approveOrRejectProposal(id, value, web3reactContext);
      if (res.code === 4001) {
        notify("Transaction Rejected");
      } else {
        notify("Transaction Confirmed");
        setProgress(false);
        navigate("/proposalOptions");
      }
      setProgress(false);
    }
  };
  const notify = (Message) => toast(Message);

  return (
    <BigPaperCover to={customRoute}>
      <p className="4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-8 2xl:text-4xl 2xl:mb-5 2xl:mt-6 text-2xl font-semibold font-Nixie mb-3">
        Proposal
      </p>
      <div className="overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm h-11/12 w-full pr-4 pb-2 3xl:text-4xl 2xl:mt-3 2xl:text-3xl  tracking-widest">
        <p className="w-full">
          <span className="underline underline-offset-1 mr-1 2xl:mb-3">
            Proposal:
          </span>{" "}
          ({proposalData?.description?.projectName})
        </p>

        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Guardian Information
          </p>
          <p className="font-semibold 2xl:mb-3 2xl:mt-3">Guardian Name -</p>
          <p> {proposalData?.description?.guardianProposal}</p>
          <p>
            <span className="font-semibold 2xl:mb-3 2xl:mt-3">
              Guardian Social Handle -
            </span>
            {proposalData?.description?.guardianDiscord}
          </p>
          <p className="font-semibold 2xl:mb-3 2xl:mt-3">Guardian Wallet -</p>
          <p>{proposalData?.description?.guardianAddress}</p>
        </div>

        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Project Summary
          </p>
          <p>{proposalData?.description?.shortDescription}</p>
        </div>

        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Project Docs
          </p>

          {proposalData?.description?.file && (
            <>
              <p className="font-semibold 2xl:mb-3 2xl:mt-3">
                Lite/Whitepaper -
              </p>
              <p>{proposalData?.description?.file}</p>
            </>
          )}

          <p className="font-semibold 2xl:mb-3 2xl:mt-3">Social Docs -</p>
          <p>{proposalData?.description?.socialChannel}</p>

          <p className="font-semibold 2xl:mb-3 2xl:mt-3">Audits -</p>
          <p>{proposalData?.description?.links}</p>
        </div>

        {proposalData?.description?.range && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              CULT Reward Allocation
            </p>
            <p>{proposalData?.description?.range}% of Total Supply</p>
          </div>
        )}

        {proposalData?.description?.rate && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              Reward Distribution Terms
            </p>
            <p>
              {parseFloat(proposalData?.description?.rate)}% per{" "}
              {proposalData?.description?.time}.
            </p>
          </div>
        )}

        {proposalData?.description?.proposalUpdatesOverTime && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              Who will provide updates on the Proposal over time?
            </p>
            <p>{proposalData?.description?.proposalUpdatesOverTime}</p>
          </div>
        )}

        {proposalData?.description?.proposalAllocation && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              Is the proposal for a single allocation or multiple?
            </p>
            <p>{proposalData?.description?.proposalAllocation}</p>
          </div>
        )}

        {proposalData?.description?.manifestoOutlinedFit && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              How does this fit the 3 tenants outlined in the Manifesto?
            </p>
            <p>{proposalData?.description?.manifestoOutlinedFit}</p>
          </div>
        )}

        {proposalData?.description?.projectProposalStage && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              At what stage is the project related to the proposal at now?
            </p>
            <p>{proposalData?.description?.projectProposalStage}</p>
          </div>
        )}

        {proposalData?.description?.returnModel && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              Outline the return model. How will the DAO be rewarded?
            </p>
            <p>{proposalData?.description?.returnModel}</p>
          </div>
        )}

        {proposalData?.description?.proposedTimeline && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              Is there a proposed timeline or target for returns to the DAO?
            </p>
            <p>{proposalData?.description?.proposedTimeline}</p>
          </div>
        )}
        
        {proposalData?.description?.fundsStoredHeldUtilised && (
          <div className="mt-3">
            <p className="underline underline-offset-1 mr-1 2xl:mb-3">
              How are the funds being stored/held/utilised?
            </p>
            <p>{proposalData?.description?.fundsStoredHeldUtilised}</p>
          </div>
        )}

        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Project Ethereum Wallet
          </p>
          <p>{proposalData?.description?.wallet}</p>
        </div>

        {proposalData?.proposer === currentAccount &&
        (proposalData?.stateName === "Pending" ||
          proposalData?.stateName === "Active" ||
          proposalData?.stateName === "Succeeded" ||
          proposalData?.stateName === "Queued") ? (
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-6/12 w-7/12 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() => cancelP(proposalData?.id)}
            >
              Cancel Proposal
            </button>
          </div>
        ) : !proposalData?.voteCount?.hasVoted &&
          proposalData?.stateName === "Active" ? (
          <div className="w-11/12 flex items-center m-auto">
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-32 w-28 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() =>
                approveProposalOrReject(
                  proposalData?.id,
                  1,
                  proposalData?.startBlock
                )
              }
            >
              Approve
            </button>
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-32 w-28 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() =>
                approveProposalOrReject(
                  proposalData?.id,
                  0,
                  proposalData?.startBlock
                )
              }
            >
              Reject
            </button>
          </div>
        ) : (
          ""
        )}
        {!proposalData?.completeDetails ? (
          <Signature
            proposalData={proposalData}
            setProgress={setProgress}
            setProgressMessage={setProgressMessage}
          />
        ) : (
          ""
        )}
      </div>
      <Link
        to={baseUrl + customRoute}
        className="text-lg font-extrabold underline font-Nixie absolute -bottom-6 sm:invisible visible"
      >
        Back
      </Link>
      {networkId != network ? (
        <p className="sm:text-xl text-base">Wrong Network...</p>
      ) : (
        ""
      )}
      {transactionInProgress ? (
        <div className="flex items-center space-x-0">
          <p className="text-lg pr-3">{progressMessage}</p>
          <span>
            <AiOutlineLoading3Quarters className="animate-spin w-6 h-6" />
          </span>
        </div>
      ) : (
        ""
      )}
      <ToastContainer hideProgressBar={true} />
    </BigPaperCover>
  );
}

export default ProposalDetail;
