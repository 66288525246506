import React, { createContext, useCallback, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Header from "./Components/Header";
import Stake from "./Components/Stake/Stake";
import ProposalOptions from "./Components/Proposal/ProposalOptions";
import SubmitProposal from "./Components/Proposal/SubmitProposal";
import PendingProposal from "./Components/Proposal/PendingProposal";
import ClosedProposal from "./Components/Proposal/ClosedProposal";
import ProposalDetail from "./Components/Proposal/ProposalDetail";
import { useDispatch, useSelector } from "react-redux";

import {
  allProposal,
  proposalLoading,
  setNetworkId,
  setDashboard,
  setAccount,
  selectProposal,
} from "./features/counter/proposalSlice";
import {
  getProposalFromAPIorLocalStorage,
  totalInvestment,
} from "./Common/governanceFunctions";
import Delegate from "./Components/Delegate";
import {
  hexToNumber,
  totalBurned,
  totalCultStaked,
  totalStaked,
  treasuryBalance,
} from "./Common/cultDaoFunctions";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import createWalletProvider, {
  LogoutWalletConnect,
} from "./Components/WalletConnect/wallectConnectUtility";
import { apiBaseUrl, network } from "./Common/config";
import { useWeb3React } from "@web3-react/core";
import {
  resetWalletConnector,
  walletconnect,
} from "./Components/WalletConnect/connector";

const web3 = require("web3");
export const AuthContext = createContext();
function App() {
  const { networkId, account } = useSelector(selectProposal);
  const [web3Instance, setWeb3Instance] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const notify = (Message) => toast(Message);
  const { deactivate } = useWeb3React();
  const web3reactContext = useWeb3React();
  const setProposalList = async () => {
    const newP = await getProposalFromAPIorLocalStorage(web3reactContext);
    if(newP !== 'error')
      dispatch(allProposal(newP));
  };

  useEffect(() => {
    const setClosedProposalList = async () => {
      let closedProposalsId = [];
      let closedProposals = [];
      let cProposal;
      try {
        const newData = await fetch(`${apiBaseUrl}proposals/pending`);
        cProposal = await newData.json();
      } catch (e) {
        console.log(e);
      }

      if (cProposal) {
        try {
          for (const element of cProposal.data) {
            if (
              element.stateName === "Canceled" ||
              element.stateName === "Defeated" ||
              element.stateName === "Expired" ||
              element.stateName === "Executed"
            ) {
              closedProposalsId.push(parseInt(element.id));
              closedProposals.push(element);
            }
          }
          localStorage.setItem(
            "closedProposalsId",
            JSON.stringify(closedProposalsId)
          );
          localStorage.setItem("proposals", JSON.stringify(closedProposals));
        } catch (e) {
          console.log(e);
        }
      }
    };
    setTimeout(setClosedProposalList, 6000);
  }, []);
  //Function to check ccount and network changes
  const checkProvider = useCallback(async (walletValue) => {
   
    const provider = await createWalletProvider(walletValue);
    provider?.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        localStorage.setItem("account", accounts[0]);
        dispatch(setAccount(accounts[0]));
      } else {
        LogoutWalletConnect(dispatch);
      }
    });
    provider?.on("chainChanged", (chainId) => {
      if (hexToNumber(chainId, web3) !== network) {
        notify("Wrong Network");
        // localStorage.setItem("chainId", hexToNumber(chainId, web3));
        // dispatch(setNetworkId(localStorage.getItem("chainId")));
        try {
          deactivate();
          LogoutWalletConnect(dispatch);
        } catch (ex) {
          console.log(ex);
        }
      } else {
        localStorage.setItem("chainId", hexToNumber(chainId, web3));
        dispatch(setNetworkId(localStorage.getItem("chainId")));
      }
    });
  }, []);

  useEffect(() => {
    const walletValue = localStorage.getItem("wallet");
    if (walletValue !== undefined) {
      checkProvider(walletValue);
    }
  }, []);

  useEffect(() => {
    dispatch(proposalLoading(true));
    const cDataFromAPIorSCF = async () => {
      let totalStakedAmount = 0;
      let totalCultStake;
      let cultBurned;
      let totalTreasuryBalance;
      let totalInvestmentFund;
      let cultEthPrice;
      let APR;
      let CURRENT_PRICE;
      let GAURDIAN_AMOUNT = 12000000000;
      let MC;

      let cData;

      try {
        const data = await fetch(`${apiBaseUrl}static/cultD.json`);
        cData = await data.json();
      } catch (e) {
        cData = { totalStakedAmount };
      }

      if (!cData) {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();
        CURRENT_PRICE = tokenPrice["cult-dao"].usd;

        const cultData2 = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=eth"
        );
        let data2 = await cultData2.json();
        cultEthPrice = data2["cult-dao"]["eth"];

        const mcApiData = await fetch(
          "https://api.coingecko.com/api/v3/coins/cult-dao"
        );
        let mcData = await mcApiData.json();
        MC = mcData.market_data.market_cap.usd;

        APR = 21.618504065064524;
        totalStakedAmount = await totalStaked(web3reactContext);
        totalCultStake = await totalCultStaked(web3reactContext);
        cultBurned = await totalBurned(web3reactContext);
        totalTreasuryBalance = await treasuryBalance(web3reactContext);
        totalInvestmentFund = await totalInvestment(web3reactContext);

        cData = {
          totalStakedAmount,
          totalCultStake,
          cultBurned,
          totalTreasuryBalance,
          totalInvestmentFund,
          GAURDIAN_AMOUNT,
          APR,
          CURRENT_PRICE,
          cultEthPrice,
          MC,
        };

        dispatch(
          setDashboard({
            cData,
          })
        );
      } else {
        if (!cData.totalStakedAmount) {
          totalStakedAmount = await totalStaked();
          cData = { ...cData, totalStakedAmount };
        }
        if (!cData.totalCultStake) {
          totalCultStake = await totalCultStaked();
          cData = { ...cData, totalCultStake };
        }
        if (!cData.cultBurned) {
          cultBurned = await totalBurned();
          cData = { ...cData, cultBurned };
        }
        if (!cData.totalTreasuryBalance) {
          totalTreasuryBalance = await treasuryBalance();
          cData = { ...cData, totalTreasuryBalance };
        }
        if (!cData.totalInvestmentFund) {
          totalInvestmentFund = await totalInvestment();
          cData = { ...cData, totalInvestmentFund };
        }
        if (!cData.MC) {
          const mcApiData = await fetch(
            "https://api.coingecko.com/api/v3/coins/cult-dao"
          );
          let mcData = await mcApiData.json();
          MC = mcData.market_data.market_cap.usd;
          cData = { ...cData, MC };
        }
        if (!cData.CURRENT_PRICE) {
          const tokenResponse = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=USD"
          );
          let tokenPrice = await tokenResponse.json();
          CURRENT_PRICE = tokenPrice["cult-dao"].usd;
          cData = { ...cData, CURRENT_PRICE };
        }
        if (!cData.GAURDIAN_AMOUNT) {
          cData = { ...cData, GAURDIAN_AMOUNT };
        }
        if (!cData.cultEthPrice) {
          const cultData2 = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=eth"
          );
          let data2 = await cultData2.json();
          cultEthPrice = data2["cult-dao"]["eth"];
          cData = { ...cData, cultEthPrice };
        }
        if (!cData.APR) {
          APR = 21.618504065064524;
          cData = { ...cData, APR };
        }
      }
      dispatch(
        setDashboard({
          cData,
        })
      );
      await setProposalList();
      dispatch(proposalLoading(false));
    };
    setTimeout(cDataFromAPIorSCF, 3000);
  }, [web3Instance, networkId, account]);

  useEffect(() => {
    let interval;
    const callFunction = async () => {
      interval = setInterval(() => {
        const reloadData = async () => {
          await setProposalList();
        };
        reloadData();
      }, 50000);
    };
    callFunction();
    return () => {
      clearInterval(interval);
    };
  }, [web3Instance, networkId, account]);

  return (
    <>
      <AuthContext.Provider value={{ web3Instance, setWeb3Instance }}>
        <div className="App overflow-hidden  h-full w-full ">
          <Header />
          <div className="4xl:mt-0 3xl:mt-8 flex flex-col w-full h-full">
            <div className="2xl:mt-6 2xl:w-10/12 2xl:mb-0 sm:w-8/12 relative w-full m-auto sm:max-h-70 sm:min-h-75 max-h-125 min-h-115 flex sm:justify-between justify-evenly">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="stake" element={<Stake />} />
                <Route path="proposalOptions" element={<ProposalOptions />} />
                <Route path="submitProposal" element={<SubmitProposal />} />
                <Route path="pendingProposal" element={<PendingProposal />} />
                <Route path="closedProposal" element={<ClosedProposal />} />
                <Route path="proposalDetail" element={<ProposalDetail />} />
                <Route path="delegate" element={<Delegate />} />
              </Routes>
            </div>
            <div
              className={` sm:w-2/5 lg:w-1/5 w-full m-auto 4xl:mt-24 2xl:mt-10 sm:mt-8 mt-3 flex justify-evenly sm:fixed sm:bottom-5 sm:left-40per sm:my-0 sm:mx-auto
           ${
             location?.pathname === "/" ||
             location?.pathname === "/proposalOptions"
               ? "-mt-4"
               : ""
           }`}
            >
              <a
                href="https://twitter.com/wearecultdao"
                target="_blank"
                className="hover:animate-bounce"
                rel="noreferrer"
              >
                <img
                  className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
                  src="images/twitter.png"
                  alt="not found"
                />
              </a>

              <a
                href="https://discord.gg/cultdao"
                target="_blank"
                className="hover:animate-bounce"
                rel="noreferrer"
              >
                <img
                  className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14 "
                  src="images/Discord.png"
                  alt="not found"
                />
              </a>
              <a
                href="https://t.me/cultdaothemany"
                target="_blank"
                className="hover:animate-bounce"
                rel="noreferrer"
              >
                <img
                  className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
                  src="images/telegram.png"
                  alt="not found"
                />
              </a>
              <a
                href="http://doc.cultdao.io/"
                target="_blank"
                className="hover:animate-bounce"
                rel="noreferrer"
              >
                <img
                  className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
                  src="images/book.png"
                  alt="not found"
                />
              </a>
              <a
                href="https://wearecultdao.medium.com"
                target="_blank"
                className="hover:animate-bounce"
                rel="noreferrer"
              >
                <img
                  className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
                  src="images/Medium.png"
                  alt="not found"
                />
              </a>
            </div>

            <div className=" w-full m-auto flex justify-center">
              <a
                className="2xl:w-48 2xl:mb-8 md:visible md:w-auto md:absolute md:right-2 md:bottom-12 md:ml-40 mt-3 static"
                href="https://revolt.cultdao.io/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className=""
                  src="images/Revolt2Earn.png"
                  alt="not found"
                />
              </a>
            </div>

            <div
              className="3xl:text-3xl 2xl:text-2xl md:absolute md:right-2 md:bottom-0 md:text-right md:text-sm md:mr-2 my-2 text-xs font-extrabold   text-white font-Nixie cursor-pointer text-center "
              onClick={() => {
                navigator.clipboard.writeText(
                  "0xEBd0999712218AbF3f9d6a7bdD6c5d7E60f25dDe"
                );
                notify("Address copied");
              }}
            >
              <p>Donate to our Community Marketing Wallet:</p>
              <p>0xEBd0999712218AbF3f9d6a7bdD6c5d7E60f25dDe</p>
            </div>
          </div>
          <video
            className="background object-cover -z-50 sm:visible invisible  h-full w-full"
            autoPlay
            muted
            loop
          >
            <source src="/videos/Stormy-Sky2_background.mp4" type="video/mp4" />
          </video>
          <img
            className="background -z-40 object-cover  h-full w-full"
            src="images/background.png"
            alt="Not found"
          />
          <ToastContainer autoClose={5000} hideProgressBar={true} />
        </div>
      </AuthContext.Provider>
    </>
  );
}

export default App;
