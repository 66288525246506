import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../App";
import { totalVotes } from "../../Common/cultDaoFunctions";
import { selectProposal } from "../../features/counter/proposalSlice";
import BackButton from "../BackButton";

function ProposalOptions() {
  const { account, networkId } = useSelector(selectProposal);
  const web3reactContext = useWeb3React();
  const [totalVote, setTotalVote] = useState(0);
  useEffect(() => {
    let proceed = true;
    const v = async () => {
      await totalVotes(web3reactContext).then((votes) => {
        setTotalVote(votes.toFixed(1));
      }).catch((e) => {
        toast.error(e.message)
      })
    };
    if (proceed) {
      v();
    }

    return () => {
      proceed = false;
    }
  }, [ account, networkId]);

  return (
    <div className="flex flex-col w-full m-auto items-center">
      <div className="flex w-full justify-between">
        <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
          <Link
            to="/pendingProposal"
            className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
          >
            <img
              className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
              src="images/smallPaperCover.svg"
              alt="not found"
            />
            <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                Pending
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                Proposals
              </p>
            </div>
          </Link>
          <Link
            to="/closedProposal"
            className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
          >
            <img
              className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
              src="images/smallPaperCover.svg"
              alt="not found"
            />
            <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                Closed
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                Proposals
              </p>
            </div>
          </Link>
        </div>
        <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
          <Link
            to="/submitProposal"
            className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
          >
            <img
              className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
              src="images/smallPaperCover.svg"
              alt="not found"
            />
            <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
              <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
                Submit
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
                Proposals
              </p>
            </div>
          </Link>
          <Link
            to="/delegate"
            className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
          >
            <img
              className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
              src="images/smallPaperCover.svg"
              alt="not found"
            />
            <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
              <p className="3xl:text-4xl 2xl:text-3xl text-sm sm:text-xl font-Nixie sm:font-semibold font-bold">
                Delegate Votes
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                {parseFloat(totalVote).toLocaleString()}
              </p>
            </div>
            <BackButton to="" removeUnderline={true} />
          </Link>
        </div>
      </div>
      <a
        href="https://dune.com/cultdaoio/cult-dao"
        target="_blank"
        className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28 -mt-5"
        rel="noreferrer"
      >
        <img
          className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
          src="images/smallPaperCover.svg"
          alt="not found"
        />
        <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
          <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
            Treasury
          </p>
          <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
            Dashboard
          </p>
        </div>
      </a>
    </div>
  );
}

export default ProposalOptions;
