import { Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { infuraID, switchNetworkId } from "../../Common/config";
import {
  setAccount,
  setMetamask,
  setNetworkId,
} from "../../features/counter/proposalSlice";

export const createWalletProvider = async (walletType) => {
  try {
    let options = {};
    options = {
      infuraId: infuraID,
    };
    if (
      (!!walletType && walletType?.toLocaleLowerCase() === "walletconnect") ||
      walletType?.toLocaleLowerCase() === "trust wallet" ||
      walletType?.toLocaleLowerCase() === "safepal wallet"
    ) {
      const provider = new WalletConnectProvider(options);
      await provider.enable();
      return provider;
    }
    return Web3.givenProvider;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getAccounts = async () => {
  try {
    const web3 = new Web3(window?.ethereum);
    const wallet = await web3?.eth?.getAccounts();
    return wallet;
  } catch (e) {
    return "";
  }
};

export const createWebInstance = async (setWeb3Instance) => {
  try {
    const walletType = localStorage.getItem("wallet");
    const provider = await createWalletProvider(walletType);
    setWeb3Instance(new Web3(provider));
    return new Web3(provider);
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const LogoutWalletConnect = (dispatch) => {
  dispatch(setNetworkId(""));
  dispatch(setAccount(""));
  dispatch(setMetamask(false));
  // localStorage.removeItem("connectorIdv2");
  // localStorage.removeItem("wallet");
  // localStorage.removeItem("walletconnect");
  // localStorage.removeItem("chainId");
  // localStorage.removeItem("account");
  localStorage.clear();
};

// Function to switch network to correct network
export const switchNetwork = async (provider) => {
  await provider
    ?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: switchNetworkId }],
    })
    .then((res) => {
      // console.log(res, "res")
    })
    .catch((err) => {
      console.log(err);
    });
};


export default createWalletProvider;
