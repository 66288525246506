import React from "react";
import { useDispatch } from "react-redux";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import { LogoutWalletConnect } from "./wallectConnectUtility";
import { IoClose } from "react-icons/io5";
import { useWeb3React } from "@web3-react/core";

const LogoutModal = ({ logout, setLogout, address }) => {
  const dispatch = useDispatch();
  const { deactivate } = useWeb3React();
  const notify = (Message) => toast(Message);
  const web3reactContext = useWeb3React();

  const Logout = () => {
    // if (localStorage.getItem("wallet") === "CoinBase Wallet") {
    //   deactivate();
    // }
    try {
      deactivate();
      LogoutWalletConnect(dispatch);
      setLogout(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <>
      {logout ? (
        <>
          <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-md xl:h-1/3 md:h-1/2 h-1/3 flex justify-center items-center">
              <img
                className="w-full h-full"
                src="images/extraLargePaperCover.png"
                alt="not found"
              />
              <div className="absolute sm:top-8 top-6">
                <button
                  type="button"
                  onClick={() => setLogout(false)}
                  className="close-button dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="crypto-modal"
                >
                  <IoClose className="w-5 h-5" />
                  <span className="sr-only" onClick={() => setLogout(false)}>
                    Close modal
                  </span>
                </button>

                <div className="py-4 px-6 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-base font-semibold font-Nixie text-gray-900 lg:text-xl dark:text-white">
                    Logout Wallet
                  </h3>
                </div>

                <div className="p-6 font-Nixie">
                  <div className="text-center w-full">
                    <div className="flex gap-4 items-center">
                      <h4 className="py-6 md:w-80 w-60 truncate font-bold overflow-ellipsis">
                        {address}
                      </h4>

                      <span
                        className="cursor-pointer"
                        title="Copy Address"
                        onClick={() => {
                          navigator.clipboard.writeText(address);
                          notify("Address copied");
                        }}
                      >
                        <MdContentCopy />
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => Logout()}
                      className="logout-button dark:focus:ring-gray-500"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default LogoutModal;
