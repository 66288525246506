import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProposal,
  setAccount,
  setMetamask,
} from "../../features/counter/proposalSlice";
import ConnectModal from "./ConnectModal";
import LogoutModal from "./LogoutModal";
import { useDispatch } from "react-redux";
import { createWebInstance } from "./wallectConnectUtility";
import { AuthContext } from "../../App";

function WalleltConnect() {
  const { isMetamask, account } = useSelector(selectProposal);
  const [showModal, setShowModal] = useState(false);
  const [logout, setLogout] = useState(false);
  const { setWeb3Instance } = useContext(AuthContext);
  const dispatch = useDispatch(selectProposal);

  useEffect(() => {
    let accountAddress = localStorage.getItem("account");
    if (accountAddress) {
      createWebInstance(setWeb3Instance);
      dispatch(setAccount(accountAddress));
      dispatch(setMetamask(true));
    } else {
      setWeb3Instance(null);
      dispatch(setAccount(""));
      setWeb3Instance("");
      dispatch(setMetamask(false));
    }
  }, [logout, isMetamask, dispatch, setWeb3Instance]);

  return (
    <>
      {!account ? (
        <button
          className="flex relative 3xl:w-64 2xl:w-64 xl:h-44 xl:w-44 sm:w-36 w-2/5 "
          onClick={() => setShowModal(true)}
        >
          <img
            className="3xl:w-64 3xl:mt-8 2xl:w-60 2xl:h-44 xl:w-44 xl:h-44 2xl:mt-6 sm:w-36 w-0  sm:visible invisible "
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <h1 className="3xl:mt-20 3xl:text-4xl 2xl:mt-16 2xl:ml-6 2xl:text-4xl xl:text-2xl xl:mt-12 xl:ml-5 sm:absolute sm:text-xl sm:left-4 sm:top-6 sm:ml-3 font-Nixie font-semibold m-auto sm:text-black text-white">
            {"Connect"}
          </h1>
        </button>
      ) : (
        <button
          onClick={() => setLogout(true)}
          className="flex relative 3xl:w-64 2xl:w-64 xl:h-44 xl:w-44 sm:w-36 w-2/5 "
        >
          <img
            className="3xl:w-64 3xl:mt-8 2xl:w-60 2xl:h-44 xl:w-44 xl:h-44 2xl:mt-6 sm:w-36 w-0  sm:visible invisible "
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <h1 className="3xl:mt-20 3xl:text-4xl 2xl:mt-16 2xl:ml-6 2xl:text-4xl xl:text-2xl xl:mt-12 xl:ml-5 sm:absolute sm:text-xl sm:left-4 sm:top-6 sm:ml-3 font-Nixie font-semibold m-auto sm:text-black text-white">
            {account?.toString().slice(0, 6) + "..."}
          </h1>
        </button>
      )}

      <ConnectModal showModal={showModal} setShowModal={setShowModal} />
      <LogoutModal logout={logout} setLogout={setLogout} address={account} />
    </>
  );
}

export default WalleltConnect;
