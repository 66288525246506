import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import BigPaperCover from "../BigPaperCover";
import { submitProposal } from "../../Common/governanceFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  proposalLoading,
  selectProposal,
} from "../../features/counter/proposalSlice";
import { getAccount, highestStaker } from "../../Common/cultDaoFunctions";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { apiBaseUrl, network } from "../../Common/config";
import { AuthContext } from "../../App";
import { useWeb3React } from "@web3-react/core";

function SubmitProposal() {
  // Note that we have to initialize ALL of fields with values. These
  // could come from props, but since we don’t want to prefill this form,
  // we just use an empty string. If we don’t do this, React will yell
  // at us.
  const [transactionInProgress, setProgress] = useState(false);
  const { networkId } = useSelector(selectProposal);

  const dispatch = useDispatch();

  const web3reactContext = useWeb3React();
  const navigate = useNavigate();

  const notify = (Message) => toast(Message);

  const slide = useRef("");
  const slideNumber = useRef("");

  useEffect(() => {
    let proceed = false;
    if (proceed) {
      const slider = slide.current;
      const sliderNumber = slideNumber.current;
      slider.oninput = function () {
        if (this.value === "0.25" || this.value === "5") {
          sliderNumber.innerHTML = " ";
        } else {
          sliderNumber.style.marginLeft = this.value * 19 + "%";
          sliderNumber.innerHTML = this.value;
        }
      };
    }
    return () => {
      proceed = false;
    };
  }, []);

  const validationSchema = Yup.object({
    projectName: Yup.string().required("Required"),
    guardianProposal: Yup.string().required("Required"),
    guardianDiscord: Yup.string().required("Required"),
    guardianAddress: Yup.string().min(26).required("Required"),
    shortDescription: Yup.string().required("Required"),
    proposalUpdatesOverTime: Yup.string().required("Required"),
    proposalAllocation: Yup.string().required("Required"),
    manifestoOutlinedFit: Yup.string().required("Required"),
    socialChannel: Yup.string().required("Required"),
    links: Yup.string().required("Required"),
    projectProposalStage: Yup.string().required("Required"),
    returnModel: Yup.string().required("Required"),
    proposedTimeline: Yup.string().required("Required"),
    fundsStoredHeldUtilised: Yup.string().required("Required"),
    wallet: Yup.string().min(26).required("Required"),
    /* file: Yup.string().required("Required"),
    range: Yup.string().required("Required"),
    rate: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
    checkbox1: Yup.boolean().oneOf([true], "Required"),
    checkbox2: Yup.boolean().oneOf([true], "Required"), */
  });

  const initialValues = {
    projectName: "",
    guardianProposal: "",
    guardianDiscord: "",
    guardianAddress: "",
    shortDescription: "",
    proposalUpdatesOverTime: "",
    proposalAllocation: "",
    manifestoOutlinedFit:"",
    socialChannel: "",
    links: "",
    projectProposalStage: "",
    returnModel: "",
    proposedTimeline: "",
    fundsStoredHeldUtilised: "",
    wallet:"",
    /* file: "",
    range: "",
    rate: "",
    time: "Day",
    checkbox1: "",
    checkbox2: "", */
  };

  const sProposal = async (data) => {
    const checkHighestStaker = await highestStaker(web3reactContext);
    if (!web3reactContext.account) {
      notify("Please connect with metamask");
      return 0;
    }
    if (checkHighestStaker) {
      setProgress(true);
      const res = await submitProposal(data, web3reactContext);
      if (res?.code === 4001) {
        notify("Transaction Rejected");
      } else if (res?.code === -32603) {
        notify("found an already active proposal");
      } else if (res?.code === 4002) {
        notify("Invalid Address");
      } else {
        dispatch(proposalLoading(true));
        try {
          await fetch(`${apiBaseUrl}proposal/update`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
        } catch (e) {}
        notify("Transaction Confirmed");
        navigate("/proposalOptions");
      }
      setProgress(false);
    } else {
      notify("You must be a Guardian to submit a proposal");
    }
  };

  const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
      <div className="m-3 flex flex-col">
        <label className="mb-2 w-11/12" htmlFor={props.id || props.name}>
          {label}
        </label>
        <input
          className={` ${
            props.type === "checkbox"
              ? "w-6 h-6 mt-4 3xl:w-12 3xl:h-12 2xl:w-8 2xl:h-8"
              : "pl-2 w-11/12 border-2 rounded border-slate-400 3xl:h-16"
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };
  const InputRange = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="m-3 flex flex-col">
        <label className="mb-2 w-11/12" htmlFor={props.id || props.name}>
          {label}
        </label>
        <input
          ref={slide}
          className="3xl:mt-3 w-11/12 border-2 rounded border-slate-400 form-range appearance-none h-2 p-0 bg-transparent focus:outline-none focus:ring-1 focus:shadow-none"
          {...field}
          {...props}
        />

        <div className="flex relative justify-between w-11/12 3xl:mt-3">
          <p>0.25</p>
          <p className="absolute mt-1" ref={slideNumber}></p>
          <p>5</p>
        </div>
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };

  const TextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="m-3 flex flex-col">
        <label
          className="mb-2 flex flex-col w-11/12"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        <textarea
          className="3xl:h-40 w-11/12 border-2 rounded border-slate-400 h-28 pl-2"
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };
  const MySelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="m-3 flex flex-col">
        <label htmlFor={props.id || props.name}>{label}</label>
        <select
          className="3xl:w-full 3xl:h-10 sm:w-24 w-16 mt-2 border-2 h-8 bg-white rounded border-slate-400"
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  return (
    <BigPaperCover to="proposalOptions">
      <p className="text-2xl 4xl:text-5xl 3xl:mt-10 3xl:mb-10 2xl:text-5xl 2xl:mt-6 font-semibold font-Nixie mb-3">
        Submit a Proposal
      </p>
      <Formik
        className="scroll"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            sProposal(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form className="overflow-y-scroll font-Nixie font-medium text-lg 2xl:text-3xl 3xl:text-4xl">
          <TextInput
            label="What is the Proposal name?"
            name="projectName"
            type="text"
          />
          <TextInput
            label="Name of the Guardian submitting the Proposal."
            name="guardianProposal"
            type="text"
          />
          <TextInput
            label="Guardian Discord or Twitter Handle."
            name="guardianDiscord"
            type="text"
          />
          <TextInput
            label="Guardian Wallet Address."
            name="guardianAddress"
            type="text"
          />
          {/* <TextInput
            label="What is the name of the Project?"
            name="projectName"
            type="text"
          /> */}
          <TextArea
            label="Provide a short description of the Project"
            name="shortDescription"
            type="text"
          />
          <TextInput
            label="Who will provide updates on the Proposal over time?"
            name="proposalUpdatesOverTime"
            type="text"
          />
          <TextInput
            label="Is the proposal for a single allocation or multiple?"
            name="proposalAllocation"
            type="text"
          />
          <TextArea
            label="How does this fit the 3 tenants outlined in the Manifesto?"
            name="manifestoOutlinedFit"
            type="text"
          />
          {/* <TextInput
            label="Please provide either an investment deck, a Litepaper or a whitepaper. This must include your tokenomics, will all current token owners, your projected future fund raise and all details."
            name="file"
            type="text"
          /> */}
          <TextArea
            label="Provide all social channels associated with the project."
            name="socialChannel"
            type="text"
          />
          <TextInput
            label="Provide links to any whitepaper, audits or docs if applicable"
            name="links"
            type="text"
          />
          <TextInput
            label="At what stage is the project related to the proposal at now? (e.g in development, presale, live etc)"
            name="projectProposalStage"
            type="text"
          />
          <TextInput
            label="Outline the return model. How will the DAO be rewarded?"
            name="returnModel"
            type="text"
          />
          <TextInput
            label="Is there a proposed timeline or target for returns to the DAO?"
            name="proposedTimeline"
            type="text"
          />
          <TextInput
            label="How are the funds being stored/held/utilised?"
            name="fundsStoredHeldUtilised"
            type="text"
          />
          {/* <InputRange
            label="Provide the percentage of the token supply being offered in return for 13 Ethereum worth of investment."
            name="range"
            type="range"
            min="0.25"
            max="5"
            step="0.25"
          /> */}
          {/* <div className="flex flex-col">
            <p className="m-3 w-11/12">
              Provide the rate at which the investee token will be swapped for
              CULT. For example X% per day, week or month, for x number of
              months.
            </p>
            <div className="flex w-7/12 justify-between">
              <TextInput
                label=""
                name="rate"
                type="text"
                percent="true"
                placeholder="%"
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  minWidth: "65px",
                }}
              />
              <p className="m-2 mt-5">Per</p>
              <MySelect label="" name="time">
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </MySelect>
            </div>
          </div> */}
          {/* <div className="w-11/12 flex">
            <p className="m-3 w-11/12">
              Confirm 50% of the CULT once swapped will be sent to our dCULT
              contract and 50% sent to a burn wallet.
            </p>
            <TextInput label="" name="checkbox1" type="checkbox" />
          </div>
          <div className="w-11/12 flex">
            <p className="m-3 w-11/12">
              Confirm that the anticipated IDO date is within 12 weeks of the
              end date of the proposal.
            </p>
            <TextInput label="" name="checkbox2" type="checkbox" />
          </div> */}
          <TextInput
            label="Provide the destination address for the treasury funds."
            name="wallet"
            type="text"
          />
          <div className="w-full flex flex-col items-center">
            <button
              type="submit"
              className="2xl:w-48 2xl:h-16 2xl:text-4xl border w-32 h-9 m-auto mt-8 mb-4 rounded-lg text-xl font-Nixie bg-buttonBg border-slate-600 font-semibold"
              disabled={transactionInProgress || networkId !== network}
            >
              Submit
            </button>
          </div>
        </Form>
      </Formik>
      {networkId != network ? (
        <p className="3xl:text-4xl sm:text-xl text-base">Wrong Network...</p>
      ) : (
        ""
      )}
      {transactionInProgress ? (
        <div className="flex items-center space-x-0">
          <p className="text-lg pr-3">Submitting Proposal</p>
          <span>
            <AiOutlineLoading3Quarters className="animate-spin w-6 h-6" />
          </span>
        </div>
      ) : (
        ""
      )}
      <ToastContainer hideProgressBar={true} />
    </BigPaperCover>
  );
}

export default SubmitProposal;
