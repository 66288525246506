import React, { useContext, useEffect, useState } from "react";
import { connectorLocalStorageKey, walletLocalStorageKey } from "./Config";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProposal,
  setAccount,
  setMetamask,
  setNetworkId,
} from "../../features/counter/proposalSlice";
import { hexToNumber } from "../../Common/cultDaoFunctions";
import { AuthContext } from "../../App";
import { network, switchNetworkId } from "../../Common/config";
import { useWeb3React } from "@web3-react/core";
import { chain } from "lodash";
import {
  injected,
  resetWalletConnector,
  walletconnect,
  walletlink,
} from "./connector";

export const MoreWalletCard = ({ ...props }) => {
  return (
    <button {...props}>
      <p fontSize="14px">More</p>
    </button>
  );
};

const WalletCard = ({ walletConfig, setShowModal }) => {
  const [toasts, setToasts] = useState([]);
  const { title, icon: Icon } = walletConfig;
  const { setWeb3Instance } = useContext(AuthContext);
  const notify = (Message) => toast(Message);
  const { networkId } = useSelector(selectProposal);
  const dispatch = useDispatch();
  const { chainId, account, active, library, activate } = useWeb3React();
  const web3reactContext = useWeb3React();
  const connectWallet = async () => {
    const currentChainId = await window.ethereum?.request({
      method: "eth_chainId",
    });
    if (!currentChainId || currentChainId === switchNetworkId) {
      const arr = ["trust wallet", "walletconnect", "safepal wallet"];
      if (arr.includes(walletConfig?.title?.toLocaleLowerCase())) {
        try {
          resetWalletConnector(walletconnect);
          await activate(walletconnect);
          // console.log(active, chainId, library, account, "active status1");
          localStorage.setItem(walletLocalStorageKey, walletConfig.title);
          localStorage.setItem(
            connectorLocalStorageKey,
            walletConfig.connectorId
          );
        } catch (ex) {
          console.log(ex);
        }
      } else if (
        walletConfig?.title?.toLocaleLowerCase() === "coinbase wallet"
      ) {
        try {
          await activate(walletlink);
          // console.log(active, chainId, library, account, "active status 2");
          localStorage.setItem(walletLocalStorageKey, walletConfig.title);
          localStorage.setItem(
            connectorLocalStorageKey,
            walletConfig.connectorId
          );
        } catch (ex) {
          console.log(ex);
        }
      } else {
        try {
          await activate(injected);
          // console.log(active, chainId, library, account, "active status");
          localStorage.setItem(walletLocalStorageKey, walletConfig.title);
          localStorage.setItem(
            connectorLocalStorageKey,
            walletConfig.connectorId
          );
        } catch (err) {
          console.log(err);
        }
      }
      setShowModal(false);
    } else {
      notify("Wrong Network");
    }
  };

  const handleClick = (description = "") => {
    const now = Date.now();
    const randomToast = {
      id: `id-${now}`,
      description,
    };
    setToasts((prevToasts) => [randomToast, ...prevToasts]);
  };

  const handleRemove = (id) => {
    setToasts((prevToasts) =>
      prevToasts.filter((prevToast) => prevToast.id !== id)
    );
  };

  useEffect(() => {
    // console.log(active, "outside");
    if (active) {
      // console.log(active, "inside");
      localStorage.setItem("account", account);
      localStorage.setItem("chainId", chainId);
      dispatch(setAccount(account));
      dispatch(setMetamask(true));
      dispatch(setNetworkId(chainId));
    }
  }, [active, account, chainId, dispatch]);
  return (
    <>
      <button
        type="button"
        onClick={() => connectWallet()}
        id={`wallet-connect-${title?.toLocaleLowerCase()}`}
        className="text-gray-900 bg-gray-100 w-full hover:bg-gray-200 hover:shadow focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-sm text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 group"
      >
        <div className="sm:block hidden">
          <Icon width="40px" ml="100px" mb="8px" />
        </div>
        <div className="sm:hidden block">
          <Icon width="24px" ml="100px" mb="4px" />
        </div>
        <span className="flex-1 ml-3 font-bold font-Nixie text-left sm:text-base text-sm whitespace-nowrap">
          {title}
        </span>
        {title === "Metamask" ? (
          <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs text-gray-700 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400 font-Nixie font-bold group-hover:bg-white">
            Popular
          </span>
        ) : null}
      </button>
      <ToastContainer toasts={toasts} onRemove={handleRemove} />
    </>
  );
};

export default WalletCard;
