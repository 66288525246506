import { batchVoteAddress, cultAddress, dCultAddress, governanceAddress } from "./Addresses";
import cultABI from "./ABI/cultABI";
import dCultABI from "./ABI/dCultABI";
import governanceABI from "./ABI/governanceABI";
import batchVoteABI from "./ABI/batchVoteABI";

import { Contract } from "@ethersproject/contracts";

export const getContract = async (library, account, ABI, Address) => {
  const signer = library.getSigner(account).connectUnchecked();
  var contract = new Contract(Address, ABI, signer);
  return contract;
};

export const contractInstance = async (web3reactContext, val) => {
  if (val === "cult") {
    const instance = await getContract(
      web3reactContext.library,
      web3reactContext.account,
      cultABI,
      cultAddress
    );
    return instance;
  } else if (val === "dcult") {
    const instance = await getContract(
      web3reactContext.library,
      web3reactContext.account,
      dCultABI,
      dCultAddress
    );
    return instance;
  } else if (val === "governance") {
    const instance = await getContract(
      web3reactContext.library,
      web3reactContext.account,
      governanceABI,
      governanceAddress
    );
    return instance;
  } else if (val === "batchVote") {
    const instance = await getContract(
      web3reactContext.library,
      web3reactContext.account,
      batchVoteABI,
      batchVoteAddress
    );
    return instance;
  }
};
