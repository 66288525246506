import React from "react";
import { Link } from "react-router-dom";
import BigPaperCover from "../BigPaperCover";
import { useSelector } from "react-redux";
import {
  selectProposal,
} from "../../features/counter/proposalSlice";
import PendingProposalItem from "./PendingProposalItem";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import { network, baseUrl } from "../../Common/config";
function PendingProposal() {
  const { proposals, loading, networkId } = useSelector(selectProposal);

  return (
    <BigPaperCover to="proposalOptions">
      <p className="4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-10 2xl:text-4xl xl:text-3xl 2xl:mb-8 2xl:mt-6 text-2xl font-semibold font-Nixie mb-4">
        Pending Proposals
      </p>
      <div className=" overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm min-w-full 2xl:max-h-55 max-h-50">
        {!loading && proposals ? (
          proposals?.length ? (
            proposals?.map((details, i) => (
              <PendingProposalItem details={details} key={i} />
            ))
          ) : (
            <div className="flex sm:w-full justify-evenly items-center">
              {networkId === network ? (
                <p className="text-xl sm:text-2xl m-auto font-semibold 3xl:text-4xl ">
                  No pending proposals at moment.
                </p>
              ) : (
                <p className="text-2xl m-auto font-semibold 3xl:text-4xl">
                  Wrong Network
                </p>
              )}
            </div>
          )
        ) : (
          <div className="flex sm:w-5/12 m-auto w-full justify-evenly items-center">
            <p className="3xl:text-6xl  3xl:mt-10 sm:text-2xl text-xl">
              Loading ...
            </p>
            <AiOutlineLoading3Quarters className="3xl:mt-10 3xl:w-16 3xl:h-16 animate-spin sm:w-8 sm:h-8 h-6 w-6" />
          </div>
        )}
      </div>
      <Link
        to={`${baseUrl}proposalOptions`}
        className="text-lg underline font-Nixie mt-3 absolute -bottom-3 sm:invisible visible"
      >
        Back
      </Link>
      <ToastContainer hideProgressBar={true} />
    </BigPaperCover>
  );
}

export default PendingProposal;
