import { useEffect } from "react";
import {
  injected,
  walletconnect,
  walletlink,
} from "../Components/WalletConnect/connector";
import { useWeb3React } from "@web3-react/core";

function ActivateNetwork({ children }) {
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React();
  useEffect(() => {
    if (localStorage.getItem("account")) {
      injected
        .isAuthorized()
        .then((isAuthorized) => {
          if (isAuthorized && !networkActive && !networkError) {
            const arr = ["trust wallet", "walletconnect", "safepal wallet"];
            if (
              arr.includes(localStorage.getItem("wallet")?.toLocaleLowerCase())
            ) {
              activateNetwork(walletconnect);
            } else if (
              localStorage.getItem("wallet")?.toLocaleLowerCase() ===
              "coinbase wallet"
            ) {
              activateNetwork(walletlink);
            } else {
              activateNetwork(injected);
            }
          }
          // activateNetwork(injected);
        })
        .catch(() => {});
    }
  }, [activateNetwork, networkActive, networkError]);

  return children;
}

export default ActivateNetwork;
