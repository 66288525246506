import { toast } from "react-toastify";
import { contractInstance } from "./contract";

export const nonces = async (account, web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "dcult");
    return await contractInst.nonces(account);
  } catch (e) {
    return "";
  }
};
export const delegateBySigs = async (resData, web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "batchVote");
    return await contractInst.delegateBySigs(resData);
  } catch (e) {
    toast.error(e.message);
    return "";
  }
};
export const castVoteBySigs = async (reqData, web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "batchVote");
    const response = await contractInst.castVoteBySigs(reqData);
    return response;
  } catch (e) {
    return "";
  }
};

export const getReceipt = async (proposalId, account, web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "governance");
    return await contractInst.getReceipt(proposalId, account);
  } catch (e) {
    return "";
  }
};
