import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  stake,
  withdraw,
  cutlDaoBalance,
  approve,
  CultAllowance,
  dCultBalance,
  pendingCult,
  claimCult,
} from "../../Common/cultDaoFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { network } from "../../Common/config";
import { AuthContext } from "../../App";
import { useWeb3React } from "@web3-react/core";

function StakingCard() {
  const { account, isMetamask, networkId } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [balance, setBalance] = useState({
    dCult: 0,
    cult: 0,
    claimableCult: 0,
  });
  const [cAllowance, setAllowance] = useState(0);
  const web3reactContext = useWeb3React();
  const initialValues = {
    stake: "",
    withdraw: "",
  };
  const [values, setValues] = useState(initialValues);

  const notify = (Message) => toast(Message);

  const data = useCallback(async () => {
    const Cbalance = await cutlDaoBalance(web3reactContext);
    const dCbalance = await dCultBalance(web3reactContext);
    const cultAllowance = await CultAllowance(web3reactContext);
    const cultClaimable = await pendingCult(web3reactContext);
    setAllowance(cultAllowance);
    setBalance({
      dCult: dCbalance,
      cult: Cbalance,
      claimableCult: cultClaimable,
    });
  }, [web3reactContext]);

  useEffect(() => {
    if (isMetamask && networkId === network && web3reactContext.library) {
      data();
    } else {
      setBalance({
        dCult: 0,
        cult: 0,
        claimableCult: 0,
      });
      setAllowance(0);
    }
  }, [web3reactContext, data, isMetamask, networkId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const approveOrStake = async () => {
    try {
      if (cAllowance > 0) {
        if (
          values.stake !== "" &&
          values.stake > 0 &&
          Number(values.stake) <= Number(balance.cult)
        ) {
          setProgress(true);
          setMessage("Depositing CULT");
          await stake(values.stake, web3reactContext);

          notify("Transaction Confirmed");
          setValues({
            stake: "",
            withdraw: "",
          });

          setProgress(false);
        } else if (values.stake > balance.cult) {
          notify("Amount is greater than your balance");
        } else if (values.stake === "") {
          notify("Please Enter Amount");
        } else {
          notify("Please Enter Valid Amount");
        }
      } else {
        setProgress(true);
        setMessage("Approving CULT");

        const approveCT = await approve(web3reactContext);
        await approveCT?.wait();
        const cultAllowance = await CultAllowance(web3reactContext);

        notify("Transaction Confirmed");
        setValues({
          stake: "",
          withdraw: "",
        });

        setAllowance(cultAllowance);
      }
    } catch (error) {
      setProgress(false);
      console.log(error);

      if (
        error?.code === 4001 ||
        error?.code === "ACTION_REJECTED" ||
        error?.message === "Cancelled by User"
      ) {
        notify("Transaction Rejected");
        setValues({
          stake: "",
          withdraw: "",
        });
      }
    }

    data();
  };

  const withdrawCult = async () => {
    try {
      if (
        values.withdraw !== "" &&
        values.withdraw > 0 &&
        Number(values.withdraw) <= Number(balance.dCult)
      ) {
        setProgress(true);
        setMessage("Withdrawing CULT");
        await withdraw(values.withdraw, web3reactContext);

        notify("Transaction Confirmed");
        setValues({
          stake: "",
          withdraw: "",
        });

        setProgress(false);
        data();
      } else if (values.withdraw > balance.dCult) {
        notify("Amount is greater than your balance");
      } else if (values.withdraw === "") {
        notify("Please Enter Amount");
      } else {
        notify("Please Enter Valid Amount");
      }
    } catch (error) {
      setProgress(false);
      console.log(error);

      if (
        error?.code === 4001 ||
        error?.code === "ACTION_REJECTED" ||
        error?.message === "Cancelled by User"
      ) {
        notify("Transaction Rejected");
        setValues({
          stake: "",
          withdraw: "",
        });
      }
    }
  };

  const ClaimCult = async () => {
    try {
      setProgress(true);
      setMessage("Claiming Rewards");
      await claimCult(web3reactContext);

      notify("Transaction Confirmed");
      setValues({
        stake: "",
        withdraw: "",
      });

      setProgress(false);
    } catch (error) {
      setProgress(false);

      if (
        error?.code === 4001 ||
        error?.code === "ACTION_REJECTED" ||
        error?.message === "Cancelled by User"
      ) {
        notify("Transaction Rejected");
        setValues({
          stake: "",
          withdraw: "",
        });
      }
    }

    data();
  };

  return (
    <>
      <div className="flex">
        <p className="font-Nixie font-semibold lg:text-2xl text-lg 2xl:mt-12 2xl:text-4xl 3xl:text-5xl">
          Stake CULT
        </p>
      </div>
      <div className="flex flex-col  mt-1 w-9/12">
        <div className="flex justify-between w-full m-auto">
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            Balance:
          </p>
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            {(parseInt(balance?.cult * 1000) / 1000).toLocaleString()}
          </p>
        </div>
        <div className="relative">
          <input
            className="3xl:pr-36 3xl:h-14 3xl:mt-3 2xl:h-12 2xl:mt-2 2xl:pr-28 pr-18 text-right w-full mx-auto mb-3 lg:h-9 h-7 border rounded-lg font-Nixie border-gray-600 font-bold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base "
            type="number"
            name="stake"
            value={values.stake}
            onChange={handleInputChange}
            disabled={cAllowance < 1}
          />
          {cAllowance > 0 ? (
            <button
              className="3xl:text-4xl 3xl:mt-3 3xl:h-14 3xl:w-32 2xl:text-3xl 2xl:h-12 2xl:mt-2 2xl:w-24 absolute right-0 border-l w-16 lg:h-9 h-7 m-auto  lg:text-xl text-lg font-Nixie border-gray-600 font-medium"
              onClick={() => setValues({ ...values, stake: balance?.cult })}
            >
              Max
            </button>
          ) : (
            ""
          )}
        </div>
        <button
          className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
          onClick={() =>
            balance?.cult < 1 && cAllowance > 0 && values.stake > balance?.cult
              ? notify("You don't have cult token to stake")
              : approveOrStake()
          }
          disabled={transactionInProgress || networkId != network}
        >
          {cAllowance > 0 ? "Stake" : "Approve"}
        </button>
      </div>
      <div className="flex flex-col  mt-0 w-9/12 2xl:mt-6">
        <div className="flex justify-between w-full m-auto">
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            dCULT Balance:
          </p>
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            {(parseInt(balance?.dCult * 1000) / 1000).toLocaleString()}
          </p>
        </div>
        <div className="relative">
          <input
            className="3xl:pr-36 3xl:h-14 3xl:mt-3 2xl:h-12 2xl:mt-2 2xl:pr-28 pr-18 text-right w-full mx-auto mb-3 lg:h-9 h-7 border rounded-lg font-Nixie border-gray-600 font-bold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base "
            type="number"
            name="withdraw"
            value={values.withdraw}
            onChange={handleInputChange}
          />
          <button
            className="3xl:text-4xl 3xl:mt-3 3xl:h-14 3xl:w-32 2xl:text-3xl 2xl:h-12 2xl:mt-2 2xl:w-24 absolute right-0 border-l w-16 lg:h-9 h-7 m-auto lg:text-xl text-lg font-Nixie border-gray-600 font-medium"
            onClick={() => setValues({ ...values, withdraw: balance?.dCult })}
          >
            Max
          </button>
        </div>
        <button
          className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border mb-3 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
          onClick={() =>
            balance?.dCult < 1 && values.withdraw > balance?.dCult
              ? notify("You don't have dcult token to withdraw")
              : withdrawCult()
          }
          disabled={transactionInProgress || networkId != network}
        >
          Withdraw
        </button>
      </div>
      <div className="flex flex-col  mt-0 w-9/12 2xl:mt-6">
        <div className="flex justify-between w-full m-auto">
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            Claimable Cult:
          </p>
          <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
            {(parseInt(balance?.claimableCult * 1000) / 1000).toLocaleString()}
          </p>
        </div>
        <button
          className="3xl:h-14 3xl:w-64 3xl:text-4xl  3xl:mt-3 3xl:mb-0 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border mb-3 mt-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
          onClick={() =>
            balance?.claimableCult === 0
              ? notify("You have no CULT to claim.")
              : balance?.claimableCult < 1
              ? notify("You have Insufficient balance")
              : ClaimCult()
          }
          disabled={transactionInProgress || networkId != network}
        >
          Claim Cult
        </button>
        <ToastContainer autoClose={5000} hideProgressBar={true} />
      </div>
      {networkId != network ? (
        <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 sm:text-xl text-base">
          Wrong Network...
        </p>
      ) : (
        ""
      )}
      {transactionInProgress ? (
        <div className="flex items-center space-x-0">
          <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-lg pr-3">
            {message}
          </p>
          <span>
            <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default StakingCard;
