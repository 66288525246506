import { useWeb3React } from "@web3-react/core";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AuthContext } from "../../App";
import { network } from "../../Common/config";
import { selectProposal } from "../../features/counter/proposalSlice";

import { SignMessage, Submit } from "./CastVoteSignedFunction";
const Signature = ({
  proposalData,
  setProgress,
  setProgressMessage,
}) => {
  setProgressMessage("Submitting Vote");
  const [selected, setSelected] = useState(false);
  const { networkId } = useSelector(selectProposal);
  const { web3Instance } = useContext(AuthContext)
  const web3reactContext = useWeb3React();
  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <div className="text-sm text-justify">
        If you would like to vote gaslessly by signing a transaction, use the
        below voting buttons. The votes must then be submitted ahead of the end
        timer for the votes to count. You must delegate to the gasless contract
        once before you can use this.
      </div>

      <div className="flex lg:flex-row sm:flex-col 2xs:flex-col flex-row justify-center gap-4 items-center mt-8 mb-8">
        <div className="flex md:flex-row sm:flex-col 3xs:flex-col flex-row justify-center items-center gap-4 w-full">
          <button
            type="submit"
            className={`3xl:h-16 3xl:w-60 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 lg:w-28 border sm:w-32 w-28 sm:by signingh-9 h-8 m-auto  rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold 
            ${selected ? "cursor-default" : "cursor-pointer"} 
            `}
            disabled={selected}
            onClick={() => {
              networkId != network ? toast('Wrong network') :
                SignMessage(proposalData?.id, 1, setProgress, { setSelected }, proposalData?.startBlock, web3Instance,web3reactContext);
            }}
          >
            Approve
          </button>
          <button
            type="submit"
            className={`3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 lg:w-28 border sm:w-32 w-28 sm:h-9 h-8 m-auto  rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold 
            ${selected ? "cursor-default" : "cursor-pointer"} 
            `}
            disabled={selected}
            onClick={() => {
              networkId != network ? toast('Wrong network') :
                SignMessage(proposalData?.id, 0, setProgress, { setSelected }, proposalData?.startBlock, web3Instance,web3reactContext);
            }}
          >
            Reject
          </button>
        </div>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className={`3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 lg:w-28 border sm:w-32 w-28 sm:h-9 h-8 m-auto  rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold 
             `}
            onClick={() => {
              Submit(proposalData?.id, web3reactContext, networkId);
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="text-sm text-justify">
        Note: the gasless voting will apply only 400 unique votes per
        submission. If the counter is at 400, submit the transaction to be able
        to continue voting.
      </div>
    </div>
  );
};
export default Signature;
