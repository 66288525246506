import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ to, removeUnderline }) {
  const navigate = useNavigate();
  return (
    <div
      className="fixed bottom-0 left-7 cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <div className="flex relative sm:visible invisible">
        <img
          className="3xl:w-56 2xl:w-48 sm:w-32 w-28"
          src="images/smallPaperCover.svg"
          alt="not found"
        />
        <h1 className="3xl:text-5xl 3xl:mt-4 3xl:ml-4 2xl:text-4xl 2xl:mt-3 2xl:ml-4 absolute text-lg sm:text-2xl left-9 font-semibold top-4 font-Nixie">
          Back
        </h1>
      </div>
    </div>
  );
}

export default BackButton;
