import React from "react";

function PaperCover({ children }) {
  return (
    <div className="3xl:max-h-60 lg:min-h-72 lg:max-h-72 h-96  items-center relative m-auto mt-0 2xl:mt-16">
      <img
        className="4xl:min-w-680 3xl:min-w-620 min-w-80 w-full max-w-lg lg:max-h-72 lg:min-h-72  h-96 2xl:max-h-65 2xl:min-h-65"
        src="images/card.png"
        alt="not found"
      />
      <div className="paper-cover">
        {children}
      </div>
    </div>
  );
}

export default PaperCover;
