import { ethers } from "ethers";
import { toast } from "react-toastify";
import { governanceAddress } from "../../Common/Addresses";
import { apiBaseUrl, network } from "../../Common/config";
import { checkVotingPowerForProposal } from "../../Common/cultDaoFunctions";
import axios from "axios";
import { castVoteBySigs, getReceipt } from "../../Common/signedFunctions";
export const SignMessage = async (
  proposalId,
  support,
  setProgress,
  { setSelected },
  startBlock,
  web3Instance,
  web3reactContext
) => {
  // check his voting power
  const votingPower = await checkVotingPowerForProposal(
    startBlock,
    web3reactContext
  );
  if (votingPower === "0") {
    toast("You need to delegate first to vote gaslessly.");
    return;
  }

  const isVoted = await getReceipt(
    proposalId,
    web3reactContext.account,
    web3reactContext
  );
  if (isVoted?.hasVoted) {
    toast("Already Voted");
  } else {
    try {
      const msgParams = JSON.stringify({
        domain: {
          name: "Cult Governor Bravo",
          chainId: network,
          verifyingContract: governanceAddress,
        },

        message: {
          proposalId: proposalId,
          support: support,
        },
        primaryType: "Ballot",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],

          Ballot: [
            { name: "proposalId", type: "uint256" },
            { name: "support", type: "uint8" },
          ],
        },
      });

      const web3SignTypedData = (web3Instance, account, messageParams) => {
        return new Promise((resolve, reject) => {
          setProgress(true);
          setSelected(true);
          web3Instance.currentProvider.sendAsync(
            {
              method: "eth_signTypedData_v4",
              params: [account, messageParams],
              from: account,
            },
            async (err, result) => {
              if (err) {
                console.error("ERROR1", err.message);
                toast(err.message);
                reject(err.message);
              } else if (result.error) {
                console.error("ERROR", result.error.message);
                toast(result.error.message);
                reject(result.error.message);
              } else {
                resolve(result.result);
              }
              setProgress(false);
              setSelected(false);
            }
          );
        });
      };
      const signature = await web3SignTypedData(
        web3Instance,
        web3reactContext.account,
        msgParams
      );
      const { r, s, v } = ethers.utils.splitSignature(signature);
      const reqData = {
        proposalId: proposalId,
        support: support,
        walletAddress: web3reactContext.account,
        signature: {
          v: v,
          r: r,
          s: s,
          proposalId: proposalId,
          support: support,
        },
      };

      await axios
        .post(`${apiBaseUrl}proposal/signature`, reqData)
        .then((res) => {
          if (support === 0) {
            toast("Proposal Rejected");
          } else {
            toast("Proposal Approved");
          }
        });
    } catch (err) {
      console.error(err?.message);
      toast(err?.message);
    }
  }
};

export const Submit = async (proposalId, web3reactContext, networkId) => {
  if (networkId != network) {
    toast("Wrong Network");
    return;
  }
  const response = await axios.get(
    `${apiBaseUrl}proposal/signatures/${proposalId}`
  );
  if (response?.data?.data?.length) {
    const castVote = await castVoteBySigs(
      response?.data?.data,
      web3reactContext
    );
    if (castVote?.hash) {
      toast("Submitted Successfully");
    }
  } else {
    toast("There is no pending Votes");
  }
};
