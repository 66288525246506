import { ethers } from "ethers";
import { governanceAddress } from "./Addresses";
import { apiBaseUrl, network } from "./config";
import _ from "lodash";
import { contractInstance } from "./contract";

function encodeParameters(types, values) {
  const abi = new ethers.utils.AbiCoder();
  return abi.encode(types, values);
}

export const submitProposal = async (data, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "governance");
    const proposal = await contractInst.propose(
      [governanceAddress],
      [0],
      ["_setInvesteeDetails(address)"],
      [encodeParameters(["address"], [data.wallet])],
      JSON.stringify(data)
    );
    const proposalVal = await proposal.wait()
    return proposalVal;
  } catch (error) {
    if (error?.code === 4001) {
      return error;
    } else if (error?.code === "UNPREDICTABLE_GAS_LIMIT")
      return { code: -32603 };
    else return { code: 4002 };
  }
};

const desc = (v, i) => {
  try {
    return JSON.parse(v[i - 1].returnValues?.description);
  } catch (e) {
    return "";
  }
};

export const proposalDetail = async (web3reactContext) => {
  try {
    const getProposals = localStorage.getItem("proposals");
    const networkId = localStorage.getItem("chainId");
    if (networkId != network) {
      return JSON.parse(getProposals);
    }
    const closedProposals = getProposals ? JSON.parse(getProposals) : [];
    const allProposals = [];
    let description;
    let closedProposalsId = [];
    let cpIds = localStorage.getItem("closedProposalsId");
    if (cpIds) {
      closedProposalsId = JSON.parse(cpIds);
    }
    let i = closedProposalsId.length + 1;
    const stateOptions = {
      0: "Pending",
      1: "Active",
      2: "Canceled",
      3: "Defeated",
      4: "Succeeded",
      5: "Queued",
      6: "Expired",
      7: "Executed",
    };
    const contractInst = await contractInstance(web3reactContext, "governance");
    const proposalCount = await contractInst.proposalCount();
    const v = await contractInst.getPastEvents("ProposalCreated", {
      fromBlock: 0,
      toBlock: "latest",
    });

    for (i; i <= proposalCount; i++) {
      if (!closedProposalsId.includes(i)) {
        let proposalDetails = await contractInst.proposals(i);
        let state = await contractInst.state(i);
        let stateName = stateOptions[state];
        description = desc(v, i);
        if (
          stateName === "Canceled" ||
          stateName === "Defeated" ||
          stateName === "Expired" ||
          stateName === "Executed"
        ) {
          closedProposalsId.push(i);
          closedProposals.push({
            ...proposalDetails,
            description,
            state,
            stateName,
          });
        } else {
          allProposals.push({
            ...proposalDetails,
            description,
            state,
            stateName,
          });
        }
      }
    }
    let key = "id";
    const uniqueClosedProposals = [
      ...new Map(closedProposals.map((item) => [item[key], item])).values(),
    ];
    allProposals.push(...uniqueClosedProposals);
    localStorage.setItem(
      "closedProposalsId",
      JSON.stringify(closedProposalsId)
    );
    localStorage.setItem("proposals", JSON.stringify(uniqueClosedProposals));
    return allProposals;
  } catch (error) {
    return "";
  }
};

export const approveOrRejectProposal = async (id, value, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "governance");
    const vote = await contractInst.castVote(id, value);
    return vote;
  } catch (error) {
    return error;
  }
};

export const cancelProposal = async (id, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }
    const contractInst = await contractInstance(web3reactContext, "governance");
    const cancelP = await contractInst.cancel(id);
    return cancelP;
  } catch (error) {
    return error;
  }
};

export const Queue = async (id, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }
    const contractInst = await contractInstance(web3reactContext, "governance");
    const vote = await contractInst.queue(id);
    return vote;
  } catch (error) {
    return "";
  }
};

export const Execute = async (id, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }
    const contractInst = await contractInstance(web3reactContext, "governance");
    const vote = await contractInst.execute(id);
    return vote;
  } catch (error) {
    return "";
  }
};

export const totalInvestment = async (id, web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "governance");
    const vote = await contractInst.nextInvesteeFund();

    return vote * 13;
  } catch (error) {
    return "";
  }
};

export const setProposal = async (PROPOSALS, web3reactContext) => {
  const newProposalData = [];
  const networkId = localStorage.getItem("chainId");
  
  if (!web3reactContext.account) {
    return PROPOSALS;
  }
  
  if (networkId === network && PROPOSALS && web3reactContext) {
    const contractInst = await contractInstance(web3reactContext, "governance");
    let i = 1;
    for (const element of PROPOSALS) {
      let voteCount;
      if (
        element?.stateName === "Pending" ||
        element?.stateName === "Active" ||
        element?.stateName === "Succeeded" ||
        element?.stateName === "Queued"
      ) {
        voteCount = await contractInst.getReceipt(
          element.id,
          web3reactContext.account
        );
      }
      newProposalData.push({
        ...element,
        voteCount,
      });
      i++;
    }
    return newProposalData;
  } else if (PROPOSALS) {
    return PROPOSALS;
  }
};

export const getProposalFromAPIorLocalStorage = async (web3reactContext) => {
  let newP;
  let cProposal;
  try {
    const newData = await fetch(`${apiBaseUrl}proposals/pending`);
    cProposal = await newData.json();
    if (cProposal) {
      newP = await setProposal(cProposal?.data, web3reactContext);
      const updatedList = await Promise.all(
        newP?.map(async (item, index) => {
          return { ...item };
        })
      );
      return updatedList;
    } else {
      const proposals = await proposalDetail(web3reactContext);
      newP = await setProposal(proposals, web3reactContext);
      const updatedList = await Promise.all(
        newP?.map(async (item, index) => {
          return { ...item };
        })
      );
      return updatedList;
    }
  } catch (e) {
    return 'error';
  }
};

export const updateProposal = async (
  dispatch,
  allProposal,
  web3reactContext
) => {
  await fetch(`${apiBaseUrl}proposal/update`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const newP = await getProposalFromAPIorLocalStorage(web3reactContext);
  dispatch(allProposal(newP));
};
