import { createSlice } from "@reduxjs/toolkit";
import { network } from "../../Common/config";

const initialState = {
  proposalData: {},
  account: '',
  proposals: [],
  isMetamask: false,
  networkId: network,
  loading: false,
  cultData: "",
  customRoute: "pendingProposal",
  dashboard: {
    cData: {},
    totalStakedAmount: 0,
    totalCultStake: 0,
    cultBurned: 0,
    totalTreasuryBalance: 0,
    totalInvestmentFund: 0,
  },
  approveSignature: [],
  rejectedSignature: [],
  delegateBySign: [],
};

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    currentProposal: (state, action) => {
      state.proposalData = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    allProposal: (state, action) => {
      state.proposals = action.payload;
    },
    setMetamask: (state, action) => {
      state.isMetamask = action.payload;
    },
    setNetworkId: (state, action) => {
      state.networkId = action.payload;
    },
    proposalLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCultData: (state, action) => {
      state.cultData = action.payload;
    },
    setCustomRoute: (state, action) => {
      state.customRoute = action.payload;
    },
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    setapproveSignature: (state, action) => {
      state.approveSignature.push(action.payload);
    },
    setrejectedSignature: (state, action) => {
      state.rejectedSignature.push(action.payload);
    },
    setdelegateBySign: (state, action) => {
      state.delegateBySign.push(action.payload)
    }
  },
});

export const {
  currentProposal,
  setAccount,
  allProposal,
  setMetamask,
  setNetworkId,
  proposalLoading,
  setCultData,
  setCustomRoute,
  setDashboard,
  setapproveSignature,
  setRejectedSignature,
  setdelegateBySign,
} = proposalSlice.actions;

// The function below is called a selector and allows us to select a proposalSlice from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.proposalSlice)`
export const selectProposal = (state) => state.proposal;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentproposalSlice = selectCount(getState());
//   if (currentproposalSlice % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default proposalSlice.reducer;


