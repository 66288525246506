import Metamask from "../../assets/icons/Metamask";
import WalletConnect from "../../assets/icons/WalletConnect";
import TrustWallet from "../../assets/icons/TrustWallet";
import Safepal from "../../assets/icons/Safepal";
import CoinBase from "../../assets/icons/CoinBase";

const Config = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: "Injected",
        priority: 1,
    },
    /* {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: "WalletConnect",
        priority: 2,
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: "Injected",
        priority: 3,
    }, */
    {
        title: "SafePal Wallet",
        icon: Safepal,
        connectorId: "Injected",
        priority: 4,
    },
    {
        title: "CoinBase Wallet",
        icon: CoinBase,
        connectorId: "Injected",
        priority: 5,
    },
];

export default Config;
export const connectorLocalStorageKey = "connectorIdv2";
export const walletLocalStorageKey = "wallet";