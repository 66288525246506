import {
  dCultAddress,
  deadAddress2,
  deadAddress1,
  deadAddress3,
} from "./Addresses";

import _ from "lodash";
import { contractInstance } from "./contract";
import Web3 from "web3";
const web3 = new Web3();
export const hexToNumber = (hex, web3Instance) =>
  web3Instance?.utils.hexToNumber(hex);

export const getChainId = async (web3Instance) => {
  try {
    const chainId = await web3Instance?.eth?.getChainId();
    localStorage.setItem("chainId", hexToNumber(chainId));
    const checkNetwork =
      hexToNumber(chainId) === 1 || hexToNumber(chainId) === 56;
    localStorage.setItem("correctNetwork", `${checkNetwork}`);
    return hexToNumber(chainId);
  } catch (e) {
    return "";
  }
};

export const chainChanged = async () => {
  window.ethereum.on("chainChanged", (chainId) => {
    localStorage.setItem("chainId", hexToNumber(chainId));
    window.location.reload();
  });
};

export const getBalance = async (account, web3Instance) => {
  const balance = await web3Instance.eth.getBalance(account);
  return (balance / Math.pow(10, 18)).toFixed(4);
};

// Function to stake cultToken to cultDAO.

export const stake = async (amount, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return 0;
    }
    const reqAmount = web3.utils.toWei(amount, "ether");
    const contractInst = await contractInstance(web3reactContext, "dcult");
    const cultStaked = await contractInst.deposit(0, reqAmount);
    const staked = await cultStaked.wait();
    return staked;
  } catch (error) {
    throw error;
  }
};

// Function to call withdraw amount function of cultDAO

export const withdraw = async (amount, web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return 0;
    }

    const reqAmount = web3.utils.toWei(amount, "ether");

    const contractInst = await contractInstance(web3reactContext, "dcult");

    const cultWithdraw = await contractInst.withdraw(0, reqAmount);
    const withdraw = await cultWithdraw.wait();
    return withdraw;
  } catch (error) {
    throw error;
  }
};

// Function to call claim function of cultDAO

export const claimDcult = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const cultClaim = await contractInst.claimCULT(0);
    return cultClaim;
  } catch (error) {
    return "";
  }
};

// Function to see cultDAO balance

export const dCultBalance = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const cultBalance = await contractInst.balanceOf(web3reactContext.account);
    return web3.utils.fromWei(cultBalance._hex, "ether");
  } catch (error) {
    return "";
  }
};

export const pendingCult = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const cultStaked = await contractInst.pendingCULT(
      0,
      web3reactContext.account
    );
    return web3.utils.fromWei(cultStaked._hex, "ether");
  } catch (error) {
    return [];
  }
};
export const claimCult = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    if (
      web3reactContext.account ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      web3reactContext.account ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528" ||
      web3reactContext.account === "0x9bb191D1be017f3f3A01B4DeA2E5b2A9f878364e"
    ) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const cultStaked = await contractInst.claimCULT(0);
    const claimCult = await cultStaked.wait();
    return claimCult;
  } catch (error) {
    throw error;
  }
};

export const highestStaker = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const checkHS = await contractInst.checkHighestStaker(
      0,
      web3reactContext.account
    );
    return checkHS;
  } catch (error) {
    return "";
  }
};

export const totalVotes = async (web3reactContext) => {
  if (!web3reactContext.account) {
    return 0;
  }
  const contractInst = await contractInstance(web3reactContext, "dcult");
  try {
    const votes = await contractInst.getVotes(web3reactContext.account);
    return votes / Math.pow(10, 18);
  } catch (error) {
    return 0.0;
  }
};

export const delegate = async (address, web3reactContext) => {
  if (!web3reactContext.account) {
    return 0;
  }

  if (
    _.toLower(web3reactContext.account) ===
      "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
    _.toLower(web3reactContext.account) ===
      "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
  ) {
    return 0;
  }

  const contractInst = await contractInstance(web3reactContext, "dcult");

  try {
    const delegated = await contractInst.delegate(address);
    return delegated;
  } catch (error) {
    if (error?.code === 4001) {
      return error;
    } else return { code: 4002 };
  }
};

export const totalStaked = async (web3reactContext) => {
  const contractInst = await contractInstance(web3reactContext, "dcult");

  try {
    const staked = await contractInst.totalSupply();
    return staked / Math.pow(10, 18);
  } catch (error) {
    return 0.0;
  }
};

export const totalCultStaked = async (web3reactContext) => {
  const contractInst = await contractInstance(web3reactContext, "dcult");

  try {
    const staked = await contractInst.totalCULTStaked();
    return staked / Math.pow(10, 18);
  } catch (error) {
    return 0.0;
  }
};

export const Guardianship = async (web3reactContext) => {
  const contractInst = await contractInstance(web3reactContext, "dcult");

  try {
    let highestStaker = 0;
    for (let i = 0; i < 50; i++) {
      highestStaker = await contractInst.highestStakerInPool(0, i);

      if (parseInt(highestStaker?.deposited)) {
        return (highestStaker?.deposited / Math.pow(10, 18)).toFixed(1);
      }
    }
    return 0;
  } catch (error) {
    return 0.0;
  }
};

// Function to send approved amount of cultToken to cultDAO

export const approve = async (web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "cult");
    const account = localStorage.getItem("account");
    if (!account) {
      return 0;
    }

    const UINT256_MAX =
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
    const cultApprove = await contractInst.approve(dCultAddress, UINT256_MAX);

    return cultApprove;
  } catch (error) {
    throw error;
  }
};

// Function to call allowance function of cultToken

export const CultAllowance = async (web3reactContext) => {
  if (!web3reactContext.account) {
    return 0;
  }

  const contractInst = await contractInstance(web3reactContext, "cult");
  try {
    const allowanceResponse = await contractInst.allowance(
      web3reactContext.account,
      dCultAddress
    );
    return allowanceResponse;
  } catch (error) {
    return 0.0;
  }
};

export const cutlDaoBalance = async (web3reactContext) => {
  try {
    if (!web3reactContext.account) {
      return 0;
    }
    const contractInst = await contractInstance(web3reactContext, "cult");
    const cultBalance = await contractInst.balanceOf(web3reactContext.account);

    return web3.utils.fromWei(cultBalance._hex, "ether");
  } catch (error) {
    return "";
  }
};

export const totalBurned = async (web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "cult");
    const balance1 = await contractInst.balanceOf(deadAddress1);

    const balance2 = await contractInst.balanceOf(deadAddress2);

    const balance =
      (parseInt(balance1) + parseInt(balance2)) / Math.pow(10, 18);
    return balance.toFixed(0);
  } catch (error) {
    return "";
  }
};

export const treasuryBalance = async (web3reactContext) => {
  try {
    const contractInst = await contractInstance(web3reactContext, "cult");
    const balance1 = await contractInst.balanceOf(deadAddress3);

    const balance = parseInt(balance1) / Math.pow(10, 18);

    return balance.toFixed(2);
  } catch (error) {
    return "";
  }
};

export const checkVotingPowerForProposal = async (
  blockNumber,
  web3reactContext
) => {
  try {
    if (!web3reactContext.account) {
      return "0";
    }
    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return "0";
    }
    blockNumber = blockNumber - 1;

    const contractInst = await contractInstance(web3reactContext, "dcult");
    const votingPower = await contractInst.getPastVotes(
      web3reactContext.account,
      blockNumber
    );
    return votingPower;
  } catch (error) {
    return error;
  }
};
