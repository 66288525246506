import React from "react";

const CoinBase = () => {
  return (
    <img
      src="./images/coinBase.svg"
      alt="CoinBase"
      className="sm:w-10 w-6 sm:h-10 h-6"
    />
  );
};

export default CoinBase;
