import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import ActivateNetwork from "./Common/ActivateNetwork";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
};
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={(provider) => getLibrary(provider)}>
      <ActivateNetwork>
        <Provider store={store}>
          <HashRouter>
            <App />
          </HashRouter>
        </Provider>
      </ActivateNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
