// mainnet
export const dCultAddress = "0x2d77B594B9BBaED03221F7c63Af8C4307432daF1";
export const cultAddress = "0xf0f9d895aca5c8678f706fb8216fa22957685a13";
export const governanceAddress = "0x0831172B9b136813b0B35e7cc898B1398bB4d7e7";
export const deadAddress1 = "0x000000000000000000000000000000000000dEaD";
export const deadAddress2 = "0xdEAD000000000000000042069420694206942069";
export const deadAddress3 = "0x55ac81186e1a8454c79ad78c615c43f54f87403b";
export const batchVoteAddress = "0x4aD54f4bb255529396Bd9506233d9Fb916A38975";


// testnet

// export const dCultAddress = "0xd54b1434c1e7b0513bc70d39e9ba9452085d4a4b";
// export const cultAddress = "0x6344889e9e722282d69bac85ac6d1b828c2170a4";
// export const governanceAddress = "0x8d4f1868719dbb697e4e40bea103cacb040ebb75";
// export const batchVoteAddress = "0xdb08B8c64F487412ebe830C7f14A1a18e2b28765";
