import React from "react";
import config from "./Config";
import WalletCard from "./WalletCard";
import { IoClose } from "react-icons/io5";

const ConnectModal = ({ showModal, setShowModal }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-lg xl:h-3/5 lg:h-4/5 h-3/5 flex justify-center items-center">
              <img
                className="w-full h-full"
                src="images/extraLargePaperCover.png"
                alt="not found"
              />
              <div className="absolute sm:top-16 top-8 w-5/6">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="close-button dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="crypto-modal"
                >
                  <IoClose className="w-5 h-5" />
                  <span className="sr-only" onClick={() => setShowModal(false)}>
                    Close modal
                  </span>
                </button>

                <div className="py-4 px-6 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-base  font-Nixie font-bold text-gray-900 lg:text-xl dark:text-white">
                    Connect a wallet
                  </h3>
                </div>

                <div className="p-6">
                  {/* <p className="text-sm  font-thin font-Nixie">
                   Please select 
                  </p> */}
                  <ul className="my-4 space-y-3">
                    {config.map((wallet) => (
                      <li
                        key={wallet.title}
                        className={`${
                          wallet.title === "SafePal Wallet" && "sm:hidden block"
                        }`}
                      >
                        <WalletCard
                          walletConfig={wallet}
                          showModal={showModal}
                          setShowModal={setShowModal}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ConnectModal;
