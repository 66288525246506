import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectProposal } from "../features/counter/proposalSlice";

function Dashboard(web3Instance) {
  const [cData] = useState({});
  const [TVL, setTVL] = useState(0);
  const { dashboard } = useSelector(selectProposal);

  const {
    MC,
    CURRENT_PRICE,
    totalCultStake,
    cultBurned,
    totalTreasuryBalance,
    totalInvestmentFund,
    cultEthPrice,
  } = dashboard.cData;


  useEffect(() => {
    const data = async () => {
      const tvLocked = CURRENT_PRICE && CURRENT_PRICE > 0 ? CURRENT_PRICE.toFixed(8) * totalCultStake : 0;
      setTVL(tvLocked);
    };
    data();
  }, [cData, MC, CURRENT_PRICE]);

  return (
    <>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              CULT Price
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {CURRENT_PRICE ? (
                "$" + CURRENT_PRICE.toFixed(8)
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              Marketcap
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {MC ? (
                `$${MC?.toLocaleString()}`
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>

        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 xl:w-60 2xl:w-80 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              Total Value Locked
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold 3xl:w-100 2xl:w-72 xl:w-52 sm:w-48 w-28 truncate text-center">
              {parseFloat(TVL.toFixed(3)) ? (
                "$" + parseFloat(TVL.toFixed(3)).toLocaleString()
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 mx-auto" />
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              Treasury Balance
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {totalTreasuryBalance && cultEthPrice ? (
                parseFloat(
                  (cultEthPrice.toFixed(12) * totalTreasuryBalance).toFixed(3)
                ).toLocaleString() + " ETH"
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              Total Funds Sent
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {parseFloat(totalInvestmentFund) ? (
                parseFloat(totalInvestmentFund).toLocaleString() + " " + "ETH"
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28">
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-52 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-24 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className=" 3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
              CULT Burned
            </p>
            <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold 3xl:w-100 2xl:w-72 xl:w-52 sm:w-48 w-28 truncate text-center">
              {parseInt(cultBurned) ? (
                parseInt(cultBurned).toLocaleString()
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 mx-auto" />
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
