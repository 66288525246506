import React, { useContext, useEffect, useState } from "react";
import {
  dCultBalance,
  delegate,
  getAccount,
  highestStaker,
} from "../Common/cultDaoFunctions";
import BackButton from "./BackButton";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import DelegateSignature from "./DelegateSignature";
import { AuthContext } from "../App";
import { useWeb3React } from "@web3-react/core";

function Delegate() {
  const [address, setAddress] = useState("");
  const [currentAccount, setCurrentAccount] = useState();
  const web3reactContext = useWeb3React()

  const [transactionInProgress, setProgress] = useState(false);

  const notify = (Message) => toast(Message);
  useEffect(() => {
    const d = async () => {
      setCurrentAccount(web3reactContext.account);
    };
    d();
  }, []);

  const Delegate = async () => {
    const dCbalance = await dCultBalance(web3reactContext);
    // console.log(dCbalance,"dCbalance")
    if (!currentAccount.length) {
      notify("Please connect with metamask");
      return 0;
    }
    if (dCbalance) {
      const checkHighestStaker = await highestStaker(web3reactContext);
      if (!checkHighestStaker) {
        setProgress(true);
        const res = await delegate(address, web3reactContext);

        if (res?.code === 4001) {
          notify("Transaction Rejected");
          setProgress(false);
        } else if (res?.code === 4002) {
          notify("Invalid Address");
          setProgress(false);
        } else {
          notify("Transaction Confirmed");
          setProgress(false);
        }
        setProgress(false);
        return "";
      } else {
        notify("Guardians cannot Delegate");
      }
    } else {
      notify("You don't have dCULT token to delegate");
    }
  };

  return (
    <div className=" w-full m-auto max-h-70 min-h-70 flex justify-between mt-0">
      <div className="items-center relative m-auto -mt-3 ">
        <img
          className="w-full max-h-75 min-h-75"
          src="images/extraLargePaperCover.png"
          alt="not found"
        />
        <div className="absolute top-14 w-full max-h-60 min-h-60 ">
          <div className="over w-10/12 max-h-60 min-h-60 flex flex-col items-center m-auto relative font-Nixie font-medium overflow-y-scroll 2xl:overflow-visible ">
            <p className="text-2xl font-semibold font-Nixie mb-2 xl:text-3xl 2xl:text-4xl 2xl:mt-6 3xl:text-5xl 3xl:mt-10 4xl:text-6xl 4xl:mt-12">
              Delegate Vote
            </p>
            <p className="text-xl xl:text-2xl  2xl:text-3xl 2xl:mt-6 3xl:text-4xl 3xl:mt-10 4xl:text-5xl 4xl:mt-12">
              Before you can vote, you must assign your voting rights to either
              yourself, or you can assign it to a third party. <br /> <br />Enter the Ethereum address of wallet to receive the voting rights.
            </p>

            <p className="text-xl xl:text-2xl mt-3 2xl:text-3xl 2xl:mt-6 3xl:text-4xl 3xl:mt-10  4xl:text-5xl 4xl:mt-12">

            </p>
            <input
              style={{ border: "1px solid" }}
              className="pl-2 w-full rounded border-gray-400 mt-3 2xl:h-10 2xl:mt-8 2xl:text-2xl 3xl:h-14 3xl:mt-10 3xl:text-3xl"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <button
              type="submit"
              style={{ border: "1px solid" }}
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-0 2xl:h-10 2xl:text-3xl 2xl:mt-7 sm:w-40 w-36 sm:h-8 h-7 m-auto mt-6 mb-4 rounded-md sm:text-xl text-base font-Nixie bg-buttonBg border-gray-800 font-semibold"
              onClick={() => Delegate()}
              disabled={address === ""}
            >
              Delegate
            </button>
            <p className="text-lg xl:text-base 2xl:text-xl 2xl:mt-3 3xl:text-2xl 3xl:mt-6 4xl:text-3xl 4xl:mt-10">
              If you would like to delegate without paying the gas fee, you can delegate by Signature instead. Once signed, please note that your delegation will not be complete until the someone has submitted the batch with the "Submit" button. If you wish to delegate immediately, then use the regular method and pay the gas fee.
            </p>
            <DelegateSignature address={address} setProgress={setProgress} />
          </div>
          {transactionInProgress ? (
            <div className="flex items-center  w-full -mt-1">
              <div className="m-auto flex items-center">
                <p className="text-xl pr-3 font-medium ">Delegating CULT</p>
                <span>
                  <AiOutlineLoading3Quarters className="animate-spin w-6 h-6" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
        // toastClassName={css({ fontFamily: "Times New Roman, Serif" })}
        />
        <BackButton to="proposalOptions" />
      </div>
    </div>
  );
}

export default Delegate;
