import React, { useContext, useEffect, useState } from "react";
import {
  Execute,
  Queue,
  updateProposal,
} from "../../Common/governanceFunctions";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import {
  allProposal,
  currentProposal,
  selectProposal,
} from "../../features/counter/proposalSlice";
import { Link } from "react-router-dom";
import { network, infuraID, baseUrl } from "../../Common/config";
import { AuthContext } from "../../App";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

function PendingProposalItem({ details }) {
  const [endIn, setEndIn] = useState(0);
  const dispatch = useDispatch();
  const { web3Instance } = useContext(AuthContext);
  const { networkId } = useSelector(selectProposal);
  const web3reactContext = useWeb3React();

  useEffect(() => {
    setEndInOrStatus(details, web3Instance);
    return () => {
      setEndIn(0);
    };
  }, [details]);

  const approvePercentage = (data) => {
    const favour = data?.forVotes / 10 ** 18;
    const against = data?.againstVotes / 10 ** 18;
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return (favour / total) * 100 === 100 || (favour / total) * 100 === 0
      ? (favour / total) * 100
      : ((favour / total) * 100).toFixed(2);
  };

  const rejectPercentage = (data) => {
    const favour = data?.forVotes / 10 ** 18;
    const against = data?.againstVotes / 10 ** 18;
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return (against / total) * 100 === 100 || (against / total) * 100 === 0
      ? (against / total) * 100
      : ((against / total) * 100).toFixed(2);
  };

  const setEndInOrStatus = async (data) => {
    try {
      const web3 = new Web3(`https://mainnet.infura.io/v3/${infuraID}`);
      const currentBlock = await web3.eth.getBlockNumber();
      const estimatedTime = (parseInt(data?.endBlock) - parseInt(currentBlock)) * 13.5;
      setEndIn(estimatedTime);
    } catch (e) {
      console.log(e);
      setEndIn(0);
    }
  };

  const makeQueued = async (id) => {
    await Queue(id, web3reactContext);
    updateProposal(dispatch, allProposal, web3reactContext);
  };

  const DoExecute = async (id) => {
    await Execute(id, web3reactContext);
    updateProposal(dispatch, allProposal, web3reactContext);
  };

  // Renderer callback with condition

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <>Completed</>;
    } else {
      // Render a countdown
      return (
        <span>
          {days > 9 ? days : `0${days}`}:{hours > 9 ? hours : `0${hours}`}:
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };

  const status = (data) => {
    if (data?.stateName === "Active") {
      return (
        <>
          <span className="font-semibold">
            Ends in:{" "}
            {endIn && networkId == network.toString() ? (
              <Countdown date={Date.now() + endIn * 1000} renderer={renderer} />
            ) : (
              "N/A"
            )}
          </span>
        </>
      );
    } else if (data?.stateName === "Succeeded") {
      return (
        <>
          <button
            type="submit"
            className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-0 2xl:w-32 2xl:h-10 2xl:text-2xl xl:text-xl 2xl:mt-7 border sm:w-24 w-24 sm:h-7 h-6 rounded-lg sm:text-lg text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
            onClick={() => makeQueued(data?.id)}
          >
            Queue
          </button>
        </>
      );
    } else if (data?.stateName === "Queued") {
      const time = new Date().getTime();

      if (data?.eta > time / 1000) {
        return (
          <>
            Status: <span className="font-semibold">{data?.stateName}</span>
          </>
        );
      }
      return (
        <>
          <button
            type="submit"
            className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-0 2xl:w-32 2xl:h-10 2xl:text-2xl xl:text-xl 2xl:mt-7 border sm:w-24 w-24 sm:h-7 h-6 rounded-lg sm:text-lg text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
            onClick={() => DoExecute(data?.id)}
          >
            Execute
          </button>
        </>
      );
    } else {
      return (
        <>
          Status: <span className="font-semibold">{data?.stateName}</span>
        </>
      );
    }
  };
  return (
    <div
      className="flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl w-full"
      onClick={() => {
        dispatch(currentProposal(details));
      }}
      id={details?.id}
    >
      <Link
        to={{ pathname: "/proposalDetail" }}
        state={{ ...details, completeDetails: false }}
        className="flex lg:flex-row flex-col gap-0 "
      >
        <span className="underline underline-offset-1 mr-1 3xl:mt-3 2xl:text-3xl xl:text-2xl 2xl:mt-3 ">
          Proposal({details?.id}):
        </span>
        ({details?.description?.projectName})
      </Link>
      <div className="flex flex-col  mt-2 ">
        <div className="flex flex-col sm:flex-row sm:items-center items-start 3xl:mt-3">
          <div className="3xl:w-96 2xl:mt-3 2xl:w-80 xl:w-60 sm:w-44 w-8/12 flex flex-col mr-4 border rounded border-slate-600">
            <div className="flex justify-between px-1 border-b border-slate-600 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                Approve
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {approvePercentage(details)}%
              </p>
              <div
                style={{ width: `${approvePercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
            <div className="flex  justify-between px-1 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                Reject
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {rejectPercentage(details)}%
              </p>
              <div
                style={{ width: `${rejectPercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
          </div>
          <div className="3xl:ml-10">
            <p>
              You Voted:{" "}
              <span className="font-semibold">
                {details?.voteCount?.hasVoted
                  ? details?.voteCount?.support !== "0"
                    ? "Approve"
                    : "Reject"
                  : "Pending"}
              </span>
            </p>
            <div>{status(details)}</div>
          </div>
        </div>
        <div className="3xl:mt-6 3xl:mb-6 flex flex-col sm:flex-row sm:items-center items-start justify-between px-1 border-b border-slate-600 relative font-semibold mt-2 ">
          <div className="flex flex-col ">
            <div className="flex text-sm ">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl ">
                Approved :
              </p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.forVotes / 10 ** 18).toLocaleString()}
              </p>
            </div>
            <div className="flex text-sm 3xl:mt-3">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Rejected :</p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.againstVotes / 10 ** 18).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex  text-sm">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">
              Total Votes :{" "}
            </p>
            <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
              {(
                details?.againstVotes / 10 ** 18 +
                details?.forVotes / 10 ** 18
              ).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingProposalItem;
