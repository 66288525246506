import React from "react";
import PaperCover from "../PaperCover";
import StakingCard from "./StakingCard";
import BackButton from "../BackButton";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import PopUpModal from "../PopUpModal";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Stake() {
  const { dashboard } = useSelector(selectProposal);

  const { APR, CURRENT_PRICE, GAURDIAN_AMOUNT, totalStakedAmount } =
    dashboard.cData;

  return (
    <div className=" w-full m-auto lg:max-h-70 lg:min-h-70 flex flex-col lg:flex-row md:justify-between">
      <div className="flex lg:flex-col lg:min-h-70 justify-evenly sm:h-full sm:mt-0 -mt-4">
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              CULT Price
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold 3xl:w-100 2xl:w-72 xl:w-52 sm:w-48 w-28 truncate text-center">
              {CURRENT_PRICE ? (
                "$" + CURRENT_PRICE?.toFixed(8)
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 mx-auto" />
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie sm:m-0 -m-1">
              Guardianship
            </p>
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              Token Threshold
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl  text-sm sm:text-xl font-Nixie font-semibold">
              {GAURDIAN_AMOUNT ? (
                parseInt(GAURDIAN_AMOUNT)?.toLocaleString()
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>
      </div>
      <PaperCover>
        <StakingCard />
      </PaperCover>
      <div className="flex lg:flex-col lg:max-h-70 lg:min-h-70 justify-evenly sm:h-full sm:mt-0 -mt-7 sm:mb-3 mb-6">
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              APR
            </p>
            <PopUpModal />
            <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {APR ? (
                APR.toFixed(8) + "%"
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              Total CULT Staked
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold 3xl:w-100 2xl:w-72 xl:w-52 sm:w-48 w-28 truncate text-center">
              {parseFloat(totalStakedAmount?.toFixed(0)) ? (
                parseFloat(totalStakedAmount?.toFixed(0))?.toLocaleString()
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 mx-auto" />
              )}
            </p>
          </div>
        </div>
        <BackButton to="" removeUnderline={true} />
      </div>
    </div>
  );
}

export default Stake;
