import { toast } from "react-toastify";
import { dCultAddress } from "../Common/Addresses";
import { apiBaseUrl, network, version } from "../Common/config";
import { delegateBySigs, nonces } from "../Common/signedFunctions";
// import { web3 } from "../Common/cultDaoFunctions";
import axios from "axios";
import _ from "lodash";

export const SignMessage = async (
  Delegatee,
  { setSelected },
  setProgress,
  web3Instance,
  networkId,
  web3reactContext,
  delegateCounter
) => {
  try {
    if (networkId != network) {
      toast("Wrong Network");
      return;
    }

    if (
      _.toLower(web3reactContext.account) ===
        "0x9bb191d1be017f3f3a01b4dea2e5b2a9f878364e" ||
      _.toLower(web3reactContext.account) ===
        "0x4faa1ac38675b3bde20cd2b2d62b5209006f7528"
    ) {
      return;
    }

    const nonce = await nonces(web3reactContext.account, web3reactContext);
    // console.log(nonce, "nonce");
    let delegatee = Delegatee
      ? Delegatee
      : !_.isEmpty(web3reactContext.account) && web3reactContext.account
      ? web3reactContext.account
      : "";

    if (!delegatee) {
      toast("Something went wrong. Please try again later.");
      return false;
    }

    const utcTimestamp = new Date().getTime();

    const expiry = Math.floor(utcTimestamp / 1000.0) + 604800;
    const msgParams = JSON.stringify({
      domain: {
        name: "dCULT",
        version: version,
        chainId: network,
        verifyingContract: dCultAddress,
      },

      message: {
        delegatee: delegatee,
        nonce: Number(nonce),
        expiry: expiry,
      },
      primaryType: "Delegation",
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],

        Delegation: [
          { name: "delegatee", type: "address" },
          { name: "nonce", type: "uint256" },
          { name: "expiry", type: "uint256" },
        ],
      },
    });

    const web3SignTypedData = (web3Instance, account, messageParams) => {
      setSelected(true);
      setProgress(true);
      return new Promise((resolve, reject) => {
        web3Instance.currentProvider.sendAsync(
          {
            method: "eth_signTypedData_v4",
            params: [account, messageParams],
            from: account,
          },
          async (err, result) => {
            if (err) {
              console.error("ERROR1", err.message);
              toast(err.message);
              reject(err.message);
            } else if (result.error) {
              console.error("ERROR", result.error.message);
              toast(result.error.message);
              reject(result.error.message);
            } else {
              resolve(result.result);
            }
            setSelected(false);
            setProgress(false);
          }
        );
      });
    };
    const signature = await web3SignTypedData(
      web3Instance,
      web3reactContext.account,
      msgParams
    );
    const r = "0x" + signature.substring(2).substring(0, 64);
    const s = "0x" + signature.substring(2).substring(64, 128);
    const v = parseInt(signature.substring(2).substring(128, 130), 16);
    const reqData = {
      walletAddress: web3reactContext.account,
      signature: {
        delegatee: delegatee,
        nonce: Number(nonce),
        expiry: expiry,
        v,
        r,
        s,
      },
    };

    await axios.post(`${apiBaseUrl}delegate/signature`, reqData).then((res) => {
      toast("Delegate Signed Successfully");
    });
  } catch (err) {
    console.error(err?.message);
    toast(err?.message);
  }
};

export const Submit = async (web3reactContext, networkId) => {
  try {
    if (networkId != network) {
      toast("Wrong Network");
      return;
    }
    const response = await axios.get(`${apiBaseUrl}delegate/signatures`);
    // console.log(response,"response")
    const delegateBy = await delegateBySigs(
      response?.data?.data,
      web3reactContext
    );
    // console.log(delegateBy,"delegateBy")
    if (delegateBy?.transactionHash) {
      toast("Submitted Successfully");
    }
  } catch (e) {
    console.log(e);
  }
};
