import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App";
import { network } from "../../Common/config";
import {
  currentProposal,
  selectProposal,
} from "../../features/counter/proposalSlice";

function ClosedProposalItems({ details }) {
  const dispatch = useDispatch();
  const [endIn, setEndIn] = useState(0);
  const { web3Instance } = useContext(AuthContext);
  const { networkId } = useSelector(selectProposal);

  useEffect(() => {
    setEndInOrStatus(details, web3Instance);

    return () => {
      setEndIn(0);
    };
  }, [details]);

  const approvePercentage = (data) => {
    const favour = data?.forVotes / 10 ** 18;
    const against = data?.againstVotes / 10 ** 18;
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return ((favour / total) * 100).toFixed(2);
  };

  const rejectPercentage = (data) => {
    const favour = data?.forVotes / 10 ** 18;
    const against = data?.againstVotes / 10 ** 18;
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return ((against / total) * 100).toFixed(2);
  };

  const setEndInOrStatus = async (data, webInstance) => {
    try {
      const value = await webInstance.eth.getBlock(parseInt(data?.endBlock));
      var date = new Date(value?.timestamp * 1000);
      setEndIn(date.toLocaleString("en-GB", { hour12: false }));
    } catch (e) {
      setEndIn("0");
    }
  };
  return (
    <div
      className="flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl"
      onClick={() => {
        dispatch(currentProposal(details));
      }}
      id={details?.id}
    >
      <Link to="/proposalDetail" state={{ ...details, completeDetails: true }}>
        <span className="underline underline-offset-1 mr-1 3xl:text-4xl 2xl:text-3xl xl:text-2xl 2xl:mt-3">
          Proposal({details?.id}):
        </span>
        ({details?.description?.projectName})
      </Link>
      <div className=" flex flex-col mt-2">
        <div className="flex flex-col sm:flex-row sm:items-center items-start">
          <div className="3xl:w-96 2xl:mt-3 2xl:w-80 xl:w-60 sm:w-44 w-8/12 flex flex-col mr-4 border rounded border-slate-600 ">
            <div className="flex justify-between px-1 border-b border-slate-600 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl  3xl:h-14 2xl:pt-1">
                Approve
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {approvePercentage(details)}%
              </p>
              <div
                style={{ width: `${approvePercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
            <div className="flex  justify-between px-1 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                Reject
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {rejectPercentage(details)}%
              </p>
              <div
                style={{ width: `${rejectPercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
          </div>
          <div>
            <p>
              Result:{" "}
              <span className="font-semibold">{details?.stateName}</span>
            </p>
            <div>
              {details?.stateName === "Canceled" ? (
                <br></br>
              ) : (
                <p>
                  Ended:{" "}
                  <span className="font-semibold">
                    {" "}
                    {networkId !== network ? "N/A" : endIn}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="3xl:mt-6 3xl:mb-6 2xl:mt-3 2xl:mb-3 flex flex-col sm:flex-row sm:items-center items-start justify-between px-1 border-b border-slate-600 relative font-semibold mt-2 ">
          <div className="flex flex-col">
            <div className="flex text-sm">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Approved :</p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.forVotes / 10 ** 18).toLocaleString()}
              </p>
            </div>
            <div className="flex text-sm 2xl:mt-3 ">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Rejected :</p>{" "}
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.againstVotes / 10 ** 18).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex  text-sm">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">
              Total Votes :{" "}
            </p>
            <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
              {(
                (parseFloat(details?.againstVotes) +
                  parseFloat(details?.forVotes)) /
                10 ** 18
              ).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClosedProposalItems;
